import CookieUtil from '../../service-utils/cookie-util';

const auth = () => {
  const { setAppCookie, getAppCookie } = CookieUtil();

  const setUserData = (user) => {
    if (user) {
      setAppCookie('user', JSON.stringify(user));
    }
  };

  const getUserData = () => {
    const userData = getAppCookie('user');
    return userData ? JSON.parse(userData) : null;
  };

  const setTokenData = (tokenData) => {
    if (!tokenData) return;

    if (tokenData.access_token) setAppCookie('access_token', tokenData.access_token);
    if (tokenData.refresh_token) setAppCookie('refresh_token', tokenData.refresh_token);
    if (tokenData.agencyId) setAppCookie('agencyId', tokenData.agencyId);
    if (tokenData.agencyCurrency) setAppCookie('agencyCurrency', tokenData.agencyCurrency);
    if (tokenData.roles) setAppCookie('roles', tokenData.roles);
    if (tokenData.userFullName) setAppCookie('userFullName', tokenData.userFullName);
    if (tokenData.userId) setAppCookie('userId', tokenData.userId);
    if (tokenData.tncUpdated) setAppCookie('tncUpdated', tokenData.tncUpdated);
    if (tokenData.agencyName) setAppCookie('agencyName', tokenData.agencyName);
  };

  const setAgencySelectData = ({ agency }) => {
    if (!agency) {
      console.error('setUpdatedData called with undefined agency object');
      return;
    }

    setAppCookie('agencyId', agency.agencyId || '');
    setAppCookie('agencyCurrency', agency.agencyCurrency || '');
    setAppCookie('roles', agency.role || '');
    setAppCookie('agencyName', agency.agencyName || '');
  };

  const setLoginUserData = ({ userAccessList }) => {
    if (!userAccessList) {
      console.error('setUpdatedData called with undefined agency object');
      return;
    }
    setAppCookie('agencyId', userAccessList[0]?.agencyId || '');
    setAppCookie('agencyCurrency', userAccessList[0]?.agencyCurrency || '');
    setAppCookie('roles', userAccessList[0]?.role || '');
    setAppCookie('agencyName', userAccessList[0]?.agencyName || '');
  };

  const getTokenData = () => {
    return {
      access_token: getAppCookie('access_token') || null,
      refresh_token: getAppCookie('refresh_token') || null,
      agencyId: getAppCookie('agencyId') || null,
      agencyCurrency: getAppCookie('agencyCurrency') || null,
      roles: getAppCookie('roles') || null,
      userFullName: getAppCookie('userFullName') || null,
      userId: getAppCookie('userId') || null,
      tncUpdated: getAppCookie('tncUpdated') || null,
      agencyName: getAppCookie('agencyName') || null,
    };
  };

  return {
    setUserData,
    getUserData,
    setTokenData,
    getTokenData,
    setAgencySelectData,
    setLoginUserData,
  };
};

export default auth;
