import React, { useEffect, useState, useMemo } from 'react';
import { Loader } from '../../common';
import { getCurrencySymbol } from './../../common/utils';
import { Table } from '../../components';
import { dateFormatMoment } from './../../components/format-date-moment';
import './account.scss';
import { AgencyLedgerDetails } from './../../service-utils/useApis';
import { getSessionData } from '../../service-utils';

const LadgerDetails = ({ userAgencyId }) => {
  let { agencyId, agencyCurrency } = getSessionData();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [amountRefresh, setAmountrefresh] = useState(false);

  const {
    data: agencyLedgerDetails = {},
    isLoading: isLoadingAgencyLedgerDetails,
    refetch: refetchLedgerDetails,
  } = AgencyLedgerDetails(agencyId, userAgencyId, pageNumber, pageLimit, agencyCurrency);

  useEffect(() => {
    refetchLedgerDetails();
  }, [pageNumber, pageLimit, refetchLedgerDetails, amountRefresh]);

  const tableData = useMemo(() => {
    return agencyLedgerDetails?.ledgerDetailsList || [];
  }, [agencyLedgerDetails]);

  const getTableHeader = [
    {
      dataField: 'recordDate',
      text: 'Date',
      formatter: (col, row) => dateFormatMoment(col, 'DD/MM/YYYY'),
    },
    { dataField: 'txnType', text: 'Transaction Type', formatter: (col, row) => col },
    { dataField: 'notes', text: 'Note', formatter: (col, row) => col },
    { dataField: 'txnRef', text: 'Reference', formatter: (col, row) => col },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (col, row) => `${getCurrencySymbol(col?.currency)} ${col?.amount.toFixed(2)}`,
    },
  ];

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  return (
    <div>
      {isLoadingAgencyLedgerDetails ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={agencyLedgerDetails?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'firstName',
              order: 'asc',
            },
          ]}
        />
      )}
    </div>
  );
};

export default LadgerDetails;
