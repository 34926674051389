import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  TextField,
  Box,
  Typography,
  Chip,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import Services from '../../service-utils/services';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import '../../views/register.scss';
import { isEmpty } from 'lodash';
import history from '../../history';
import { Link } from 'react-router-dom';
import '../../views/register.scss';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { Grid } from '../../common';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

export const UserRegistration = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [successStatus, setSuccessStatus] = useState(false);
  const [errorText, setErrorText] = useState();
  const { registerCode } = useParams();

  const { data, isLoading, error } = useQuery(
    ['INVITE_DATA', registerCode],
    async () => {
      const response = await Services.getInvite(registerCode);
      return response.data?.userDTO;
    },
    {
      enabled: !!registerCode,
      onError: (error) => {
        const errorMsg = error?.response?.data?.reasonDesc || 'Error in Registration.';
        // enqueueSnackbar(errorMsg, { variant: 'error' });
        // setErrorText(errorMsg);
        enqueueSnackbar(errorMsg, { variant: 'error' });
        history.push('/login');
      },
    },
  );

  const { mutate: createUserRegistration, isLoading: isLoadingCreateAgency } = useMutation(
    async (values) => {
      const { name, password } = values;
      const payload = {
        agencyId: data?.agencyId,
        id: data?.id,
        name,
        password,
      };
      const response = await Services.userDataAdded(registerCode, payload);
      return response.data;
    },
    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error in user login.';
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        // setServerErrors(err?.response?.data?.field)
        setErrorText(errorMsg);
      },
      onSuccess: () => {
        enqueueSnackbar('Registration successful.', { variant: 'success' });
        setSuccessStatus(true);
        history.push('/login');
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      name: '',
      password: '',
      rePassword: '',
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be more than 1 characters')
        .max(255, 'Must be less than 255 characters')
        .required('Name is required.'),
      email: Yup.string().email('Invalid email address').required('Email is required.'),
      password: Yup.string().required('Password is required.'),
      rePassword: Yup.string().when('password', {
        is: (val) => val,
        then: Yup.string()
          .required('Confirm Password is required.')
          .oneOf([Yup.ref('password')], 'Passwords must match.'),
        otherwise: Yup.string(),
      }),
      termsAccepted: Yup.bool().oneOf([true], 'You must accept the Terms & Conditions'),
    }),
    onSubmit: () => createUserRegistration(values),
  });

  useEffect(() => {
    if (!isEmpty(data)) {
      setFieldValue('email', data?.email); // Correct field name for email
    }
  }, [data, setFieldValue]);

  return (
    <>
      <div className="login-page">
        <Grid container className="full-height-grid">
          <Grid
            item
            size={{ xs: 12, md: 7 }}
            className="d-flex justify-content-center align-items-center bg-dark1 slider-container"
          >
            <img
              src="https://content.adzylytics.com/static/images/adzylytics_logo.png"
              alt="logo"
              className="img-fluid"
            />
          </Grid>
          <Grid
            item
            size={{ xs: 12, md: 5 }}
            className="d-flex justify-content-center align-items-center h-100 login-form-container p-4"
          >
            <div className="text-center w-100">
              <form onSubmit={handleSubmit}>
                <TextField
                  error={Boolean(touched.name && errors.name)}
                  fullWidth
                  helperText={touched.name && errors.name}
                  label="Name"
                  margin="normal"
                  name="name"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('name', event.target.value)}
                  type="name"
                  value={values.name}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.email && errors.email)}
                  fullWidth
                  helperText={touched.email && errors.email}
                  label="Email"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('email', event.target.value)}
                  type="email"
                  value={values.email}
                  variant="outlined"
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('password', event.target.value)}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />

                {serverErrors.password && (
                  <p className="text-danger mt-n1">{serverErrors.password}</p>
                )}

                <TextField
                  info="Re-enter password to confirm"
                  type="password"
                  required
                  fullWidth
                  error={Boolean(touched.rePassword && errors.rePassword)}
                  helperText={touched.rePassword && errors.rePassword}
                  label="Confirm Password"
                  margin="normal"
                  name="rePassword"
                  onBlur={handleBlur}
                  onChange={(event) => setFieldValue('rePassword', event.target.value)}
                  value={values.rePassword}
                  variant="outlined"
                />

                {serverErrors.rePassword && (
                  <p className="text-danger mt-n2">{serverErrors.rePassword}</p>
                )}

                <Grid item size={{ xs: 12, sm: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        required
                        name="termsAccepted"
                        checked={values.termsAccepted}
                        onChange={(event) => setFieldValue('termsAccepted', event.target.checked)}
                        color="primary"
                      />
                    }
                    label={
                      <Typography variant="body2" component="span">
                        I agree to the{' '}
                        <Link
                          className="text-primary"
                          to={{ pathname: 'https://www.adzylytics.com/tnc/' }}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ display: 'inline' }}
                        >
                          Terms & Conditions
                        </Link>
                      </Typography>
                    }
                  />
                  {touched.termsAccepted && errors.termsAccepted && (
                    <Typography className="text-danger">{errors.termsAccepted}</Typography>
                  )}
                </Grid>
                {touched.termsAccepted && errors.termsAccepted && (
                  <Typography className="text-danger">{errors.termsAccepted}</Typography>
                )}

                {!!errorText && <Chip label={errorText} className="alert-danger w-100 mb-3" />}
                <Box sx={{ py: 2, maxHeight: '200px', overflow: 'auto' }}>
                  <Button
                    color="primary"
                    className="btn btn-primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    disabled={isLoadingCreateAgency}
                  >
                    {isLoadingCreateAgency ? 'Submiting...' : 'Submit'}
                  </Button>
                </Box>
              </form>
            </div>
          </Grid>
        </Grid>

        {/* Uncomment the footer if needed */}
        {/* <Footer /> */}
      </div>

      {isLoadingCreateAgency && <OverlapLoader />}
    </>
  );
};
