import React from 'react';
import { Grid } from '../common';
import './register.scss';
import logo from './../assets/img/adzy.png';
import RegistrationInvite from '../components/registration-invite/registration-invite';
export const Register = () => {
  return (
    <div className="login-page">
      <Grid container className="full-height-grid">
        <Grid
          item
          size={{ xs: 12, md: 7 }}
          md={7}
          className="d-flex justify-content-center align-items-center bg-dark1 slider-container"
        >
          <img
            src="https://content.adzylytics.com/static/images/adzylytics_logo.png"
            alt="logo"
            className="img-fluid"
          />
        </Grid>
        <Grid
          item
          size={{ xs: 12, md: 5 }}
          className="d-flex justify-content-center align-items-center h-100 login-form-container p-4"
        >
          <div className="text-center w-100">
            <RegistrationInvite />
          </div>
        </Grid>
      </Grid>

      {/* <Footer /> */}
    </div>
  );
};
