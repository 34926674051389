import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  NewTextField,
  Paper,
  Grid,
  NewSelect,
  Button,
  Typography,
  NewDatePicker,
} from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { CAMPAIGN_STATUS } from '../../libs/constants';
import { dateFormatMoment } from '../../components/format-date-moment';
import { getMovementFromFormat } from '../../components/format-date-moment';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from './../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { Box, Checkbox, FormControlLabel, IconButton, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

export const CampaignCreate = () => {
  const { campaignId } = useParams();
  let { agencyId, agencyCurrency, advertiserId, tncUpdated } = getSessionData();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const [serverErrors, setServerErrors] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [subIabCategories, setSubIabCategories] = useState([]);

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
          action: () => history.goBack(),
        },
      }),
    );
  }, [dispatch]);

  const pageLimit = 5000;
  const pageNumber = 0;

  const { data: allAdvertiser = [] } = useQuery(
    ['ALL_ADVERTISERS', pageLimit, pageNumber, agencyId],
    async () => {
      const payload = {
        pageSize: pageLimit,
        pageNum: pageNumber,
        enabled: true,
        agencyId,
      };
      const response = await Services.advertisersGetAll(queryString.stringify(payload));
      return response.data?.advertiserList;
    },
    { enabled: !!agencyId },
  );

  const { data: campaignData = {} } = useQuery(
    ['CAMPAIGN_DATA', campaignId, agencyId, advertiserId],
    async () => {
      const payload = {
        agencyId,
        currency: agencyCurrency,
      };
      const response = await Services.campaignGetDetails(
        campaignId,
        advertiserId,
        queryString.stringify(payload),
      );
      return response.data?.campaignDTO;
    },
    { enabled: !!campaignId && !!agencyId },
  );

  const { data: categoriesData = [] } = useQuery(
    ['IAB_DATA', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.iabCategories(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId },
  );

  const { data: timezoneData = [] } = useQuery(
    ['TIMEZONE_DATA', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getMasterTimezone(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId },
  );

  const { data: exchangeData = [] } = useQuery(
    ['EXCHANGE_DATA', agencyId],
    async () => {
      const payload = { agencyId };
      const response = await Services.getExchangeList(queryString.stringify(payload));
      return response.data?.masterList;
    },
    { enabled: !!agencyId },
  );

  const timezone_options = useMemo(
    () =>
      timezoneData.map((item) => ({
        label: item?.label,
        value: item?.label,
      })),
    [timezoneData],
  );

  const exchange_options = useMemo(
    () =>
      exchangeData.map((item) => ({
        label: item?.label,
        value: item?.id,
      })),
    [exchangeData],
  );

  const iabCategories = useMemo(
    () =>
      categoriesData?.map((item) => ({
        label: item.label,
        value: item.id,
      })),
    [categoriesData],
  );

  useEffect(() => {
    if (selectedCategoryId) {
      // Find the selected category in the categoriesData
      const selectedCategory = categoriesData.find((cat) => cat.id === selectedCategoryId);

      // Extract and transform subcategories
      const subcats =
        selectedCategory?.children?.map((child) => ({
          label: child.label,
          value: child.id,
        })) || [];

      setSubIabCategories(orderBy(subcats, 'label'));
    } else {
      setSubIabCategories([]);
    }
  }, [selectedCategoryId, categoriesData]);

  const handleCategoryChange = (obj) => {
    setFieldValue('iabCat', obj);
    setSelectedCategoryId(obj.value);
    setFieldValue('iabSubCat', null);
  };

  const { mutate: createCampaign, isLoading: isLoadingCreateCampaign } = useMutation(
    async (values) => {
      const {
        name,
        advertiser,
        status,
        startDate,
        endDate,
        iabCat,
        ipBasedFrequency,
        ipBasedFrequencyNoOfDays,
        userBasedFrequency,
        userBasedFrequencyNoOfDays,
        timezone,
        userTimeZone,
        iabSubCat,
        campaignExchangeSeatIds = [],
      } = values;

      const payload = {
        name: name,
        enabled: status.value,
        advertiserId: advertiser.value,
        advertiserName: advertiser.label,
        iabCategoryId: iabCat.value,
        iabSubCategoryId: iabSubCat?.value,
        ipBasedFrequency: parseInt(ipBasedFrequency),
        ipBasedFrequencyNoOfDays: parseInt(ipBasedFrequencyNoOfDays),
        userBasedFrequency: parseInt(userBasedFrequency),
        userBasedFrequencyNoOfDays: parseInt(userBasedFrequencyNoOfDays),
        campaignExchangeSeatIds: values.isSeat
          ? campaignExchangeSeatIds.map((item) => ({
              exchangeId: item.exchange.value,
              seatId: item.seatId,
            }))
          : [],
        isSeat: values.isSeat,
        startDateTime: dateFormatMoment(startDate, 'DD/MM/yyyy HH:mm'),
        endDateTime: dateFormatMoment(endDate, 'DD/MM/yyyy HH:mm'),
        userTimeZone: userTimeZone,
        timezone: timezone.value,
      };

      const query = queryString.stringify({ agencyId });
      const response = !!campaignId
        ? await Services.campaignUpdate(advertiserId, campaignId, query, payload)
        : await Services.campaignCreate(advertiserId, query, payload);
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        const successMessage = !!campaignId
          ? 'Campaign updated successfully.'
          : 'Campaign created successfully.';

        enqueueSnackbar(successMessage, { variant: 'success' });
        history.push(`/advertiser/campaign?advertiserId=${values.advertiser.value}`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: campaignData?.name || '',
      iabCat: campaignData?.iabCat
        ? { label: campaignData?.iabCat, value: campaignData?.iabCat }
        : {},
      iabSubCat: campaignData?.iabSubCat
        ? { label: campaignData?.iabSubCat, value: campaignData?.iabSubCat }
        : null,
      advertiser: campaignData?.advertiserId
        ? { label: campaignData?.advertiserName, value: campaignData?.advertiserId }
        : {},
      status: campaignData?.status
        ? { label: campaignData?.status, value: campaignData?.status }
        : {},
      startDate: campaignData?.startDateTime
        ? getMovementFromFormat(campaignData?.startDateTime)
        : moment().add(20, 'minutes'),
      endDate: campaignData?.endDateTime
        ? getMovementFromFormat(campaignData?.endDateTime)
        : moment().add(15, 'days'),
      timezone: campaignData?.timezone
        ? {
            label: campaignData.timezone,
            value: campaignData.timezone,
          }
        : {},
      ipBasedFrequency: campaignData?.ipBasedFrequency,
      ipBasedFrequencyNoOfDays: campaignData?.ipBasedFrequencyNoOfDays,
      userBasedFrequency: campaignData?.userBasedFrequency,
      userBasedFrequencyNoOfDays: campaignData?.userBasedFrequencyNoOfDays,
      userTimeZone: false,
      isSeat: false,
      campaignExchangeSeatIds: [{ exchange: null, seatId: '' }],
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Campaign name is required.'),
    }),
    onSubmit: () => createCampaign(values),
  });

  const campaignExchangeSeatIds = useMemo(() => {
    return campaignData?.isSeat
      ? campaignData.campaignExchangeSeatIds.map((seat) => ({
          exchange: exchange_options.find((opt) => opt.value === seat.exchangeId),
          seatId: seat.seatId,
        }))
      : [{ exchange: null, seatId: '' }];
  }, [campaignData, exchange_options]);

  useEffect(() => {
    const advertiser = allAdvertiser.filter((item) => item.id === campaignData?.advertiserId)[0];

    const selectCat = iabCategories.filter((item) => item.value === campaignData?.iabCategoryId)[0];
    campaignData?.iabCategoryId && setFieldValue('iabCat', selectCat);

    if (campaignData?.iabCategoryId) {
      // Set the main category
      setFieldValue('iabCat', selectCat);
      setSelectedCategoryId(campaignData.iabCategoryId);

      // Find and set the sub category if it exists
      if (campaignData?.iabSubCategoryId) {
        const selectedCategory = categoriesData.find(
          (cat) => cat.id === campaignData.iabCategoryId,
        );
        const subCat = selectedCategory?.children?.find(
          (child) => child.id === campaignData.iabSubCategoryId,
        );

        if (subCat) {
          setFieldValue('iabSubCat', {
            label: subCat.label,
            value: subCat.id,
          });
        }
      }
    }
    campaignData?.name && setFieldValue('name', campaignData?.name);
    campaignData?.seatId && setFieldValue('seatId', campaignData?.seatId);
    if (campaignData?.isSeat !== undefined) {
      setFieldValue('isSeat', campaignData.isSeat);
      setFieldValue('campaignExchangeSeatIds', campaignExchangeSeatIds);
    }

    campaignData?.timezone &&
      setFieldValue('timezone', {
        label: campaignData.timezone,
        value: campaignData.timezone,
      });
    campaignData?.ipBasedFrequency &&
      setFieldValue('ipBasedFrequency', campaignData?.ipBasedFrequency);
    campaignData?.ipBasedFrequencyNoOfDays &&
      setFieldValue('ipBasedFrequencyNoOfDays', campaignData?.ipBasedFrequencyNoOfDays);
    campaignData?.userBasedFrequency &&
      setFieldValue('userBasedFrequency', campaignData?.userBasedFrequency);
    campaignData?.userBasedFrequencyNoOfDays &&
      setFieldValue('userBasedFrequencyNoOfDays', campaignData?.userBasedFrequencyNoOfDays);
    campaignData?.advertiserId &&
      setFieldValue('advertiser', { label: advertiser?.name, value: advertiser?.id });
    campaignData?.name &&
      setFieldValue(
        'status',
        CAMPAIGN_STATUS.find((item) => item.value === campaignData?.enabled) || CAMPAIGN_STATUS[0],
      );
    campaignData?.startDateTime &&
      setFieldValue('startDate', getMovementFromFormat(campaignData?.startDateTime).toDate());
    campaignData?.endDateTime &&
      setFieldValue('endDate', getMovementFromFormat(campaignData?.endDateTime).toDate());
    campaignData?.userTimeZone && setFieldValue('userTimeZone', campaignData.userTimeZone);
  }, [campaignData, allAdvertiser, setFieldValue, iabCategories]);

  const removeExchangeSeatPair = (index) => {
    const updatedPairs = values.campaignExchangeSeatIds.filter((_, i) => i !== index);
    setFieldValue('campaignExchangeSeatIds', updatedPairs);
  };
  const getAvailableExchangeOptions = useMemo(() => {
    return exchange_options.filter(
      (option) =>
        !values.campaignExchangeSeatIds.some(
          (pair) => pair.exchange && pair.exchange.value === option.value,
        ),
    );
  }, [exchange_options, values.campaignExchangeSeatIds]);

  const addExchangeSeatPair = () => {
    if (values.campaignExchangeSeatIds.length < 50) {
      setFieldValue('campaignExchangeSeatIds', [
        ...values.campaignExchangeSeatIds,
        { exchange: null, seatId: '' },
      ]);
    }
  };

  const renderExchangeSeatFields = () => {
    return values.campaignExchangeSeatIds.map((pair, index) => (
      <React.Fragment key={index}>
        <Grid container item size={{ xs: 12, sm: 12 }} spacing={2} alignItems="center">
          <Grid item size={{ xs: 12, sm: 6 }}>
            <NewSelect
              required={values.isSeat}
              options={getAvailableExchangeOptions}
              value={pair.exchange}
              onChange={(obj) => {
                const updatedPairs = [...values.campaignExchangeSeatIds];
                updatedPairs[index].exchange = obj;
                setFieldValue('campaignExchangeSeatIds', updatedPairs);
              }}
              placeholder="Exchange"
              className="py-2 mt-1"
              isDisabled={!values.isSeat}
            />
            {serverErrors[`campaignExchangeSeatIds[${index}].exchange`] && (
              <p className="text-danger mt-1">
                {serverErrors[`campaignExchangeSeatIds[${index}].exchange`]}
              </p>
            )}
          </Grid>

          <Grid item size={{ xs: 12, sm: 6 }}>
            <NewTextField
              required={values.isSeat}
              disabled={!values.isSeat}
              error={Boolean(
                touched.campaignExchangeSeatIds?.[index]?.seatId &&
                  errors.campaignExchangeSeatIds?.[index]?.seatId,
              )}
              fullWidth
              helperText={
                touched.campaignExchangeSeatIds?.[index]?.seatId &&
                errors.campaignExchangeSeatIds?.[index]?.seatId
              }
              label="Seat Id"
              margin="normal"
              name={`campaignExchangeSeatIds[${index}].seatId`}
              onBlur={handleBlur}
              onChange={(event) => {
                const updatedPairs = [...values.campaignExchangeSeatIds];
                updatedPairs[index].seatId = event.target.value;
                setFieldValue('campaignExchangeSeatIds', updatedPairs);
              }}
              value={pair.seatId}
              variant="outlined"
            />
            {serverErrors[`campaignExchangeSeatIds[${index}].seatId`] && (
              <p className="text-danger mt-1">
                {serverErrors[`campaignExchangeSeatIds[${index}].seatId`]}
              </p>
            )}
          </Grid>

          <Grid item size={{ xs: 12, sm: 2 }} container justifyContent="center">
            {index === values.campaignExchangeSeatIds.length - 1 && (
              <>
                {values.campaignExchangeSeatIds.length < getAvailableExchangeOptions.length && (
                  <IconButton
                    className="primary-color"
                    onClick={addExchangeSeatPair}
                    disabled={!values.isSeat}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                )}
                {values.campaignExchangeSeatIds.length > 1 && (
                  <IconButton
                    className="red-color"
                    onClick={() => removeExchangeSeatPair(index)}
                    disabled={!values.isSeat}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                )}
              </>
            )}
          </Grid>
        </Grid>
      </React.Fragment>
    ));
  };

  const tncLoader = tncUpdated === 'true' && isLoadingCreateCampaign;

  return (
    <div className="col-10 p-0 bg-white rounded shadow m-auto mb-4">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!campaignId ? 'Edit Campaign' : 'Create New Campaign'}
          </Typography>
        </div>

        {campaignData.expired && (
          <div className="d-flex justify-content-center textRed">
            <Typography variant="h6">This campaign has been expired.</Typography>
          </div>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} rowSpacing={0.1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Give suitable name for your campaign e.g Diwali Campaign, Winter Season Campaign"
                context="this is campaign name"
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label="Campaign Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                required
                info="Enable the status to start campaign. Disable to pause it"
                options={CAMPAIGN_STATUS}
                value={values.status}
                onChange={(obj) => {
                  setFieldValue('status', obj);
                }}
                placeholder={`Status`}
                className="py-2"
              />
              {serverErrors.enabled && <p className="text-danger mt-n2">{serverErrors.enabled}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                required
                info="Choose the category from the list that describes your ad."
                options={orderBy(iabCategories, 'label')}
                value={values.iabCat}
                onChange={handleCategoryChange}
                placeholder={`IAB Categories`}
                className="py-2"
              />
              {serverErrors.iabCat && <p className="text-danger mt-n2">{serverErrors.iabCat}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                required
                info="Choose the Sub category from the list that describes your ad."
                options={subIabCategories}
                value={values.iabSubCat}
                onChange={(obj) => setFieldValue('iabSubCat', obj)}
                placeholder={`IAB Sub Categories`}
                disabled={!values.iabCat}
                className="py-2"
              />

              {serverErrors.iabSubCat && (
                <p className="text-danger mt-n2">{serverErrors.iabSubCat}</p>
              )}
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewDatePicker
                info="Set valid start date and time of your campaign"
                placeholder={`Start Date`}
                isSingleDate={true}
                required
                initialValue={values.startDate}
                timePicker={true}
                onCallback={(date) => setFieldValue('startDate', date)}
                key={values.startDate}
                minDate={campaignData.expired === true || campaignData.name ? null : moment()}
                isDisabled={!campaignId ? false : !campaignData?.dateChangeAllowed}
              />
              {serverErrors.startDateTime && (
                <p className="text-danger mt-n2">{serverErrors.startDateTime}</p>
              )}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewDatePicker
                info="Set valid end date and time of your campaign"
                placeholder={`End Date`}
                isSingleDate={true}
                required
                initialValue={values.endDate}
                timePicker={true}
                minDate={campaignData.expired === true ? null : moment()}
                onCallback={(date) => setFieldValue('endDate', date)}
                key={values.endDate}
                isDisabled={!campaignId ? false : !campaignData?.endDateChangeAllowed}
              />
              {serverErrors.endDateTime && (
                <p className="text-danger mt-n2">{serverErrors.endDateTime}</p>
              )}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} container className="py-0">
              <FormControlLabel
                className=""
                control={
                  <Checkbox
                    checked={values.userTimeZone}
                    onChange={(event, val) => {
                      setFieldValue('userTimeZone', val);
                    }}
                    name="checkedB"
                    color="primary"
                    className="m-0 justify-content-center"
                    disabled={campaignId}
                  />
                }
                label="User TimeZone"
              />
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                info="Select your timezone based on your region"
                options={timezone_options}
                value={values.timezone}
                required
                onChange={(obj) => {
                  setFieldValue('timezone', obj);
                }}
                placeholder={`Timezone`}
                className="py-2"
                isDisabled={campaignId || Boolean(values.userTimeZone)}
              />
              {serverErrors.timezone && (
                <p className="text-danger mt-n2">{serverErrors.timezone}</p>
              )}
            </Grid>

            <Grid item size={{ xs: 12, sm: 12 }} className="py-0">
              <Paper
                elevation={2}
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Grid item size={{ xs: 12 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.isSeat}
                        onChange={(event) => {
                          setFieldValue('isSeat', event.target.checked);
                          setFieldValue('campaignExchangeSeatIds', [
                            { exchange: null, seatId: '' },
                          ]);
                        }}
                        name="isSeat"
                      />
                    }
                    label="Is Seat"
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 12 }} spacing={2} justifyContent="center">
                  {renderExchangeSeatFields()}
                </Grid>
              </Paper>
            </Grid>
            <Grid item size={{ xs: 12, sm: 12 }} className="py-0">
              <Paper
                className="mt-2"
                elevation={2}
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Grid item size={{ xs: 12 }} className="py-0 d-flex sentance-with-input">
                  <Typography variant="paraText" classes="sentance">
                    FREQUENCY CAPPING
                  </Typography>
                </Grid>
                <Grid item size={{ xs: 12 }} className="py-0 d-flex sentance-with-input">
                  <Typography variant="paraText" classes="sentance">
                    Show per IP no more than
                  </Typography>
                  <NewTextField
                    info="Maximum impressions per ip"
                    className="mx-1"
                    error={Boolean(touched.ipBasedFrequency && errors.ipBasedFrequency)}
                    fullWidth
                    helperText={touched.ipBasedFrequency && errors.ipBasedFrequency}
                    label="IP Frequecy"
                    margin="normal"
                    name="ipBasedFrequency"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('ipBasedFrequency', event.target.value)}
                    value={values.ipBasedFrequency}
                    variant="outlined"
                  />
                  <Typography variant="paraText" classes="sentance">
                    {' '}
                    time(s){' '}
                  </Typography>
                  <NewTextField
                    className="mx-1"
                    info="Duration for the allowed impressions per ip "
                    error={Boolean(
                      touched.ipBasedFrequencyNoOfDays && errors.ipBasedFrequencyNoOfDays,
                    )}
                    fullWidth
                    helperText={touched.ipBasedFrequencyNoOfDays && errors.ipBasedFrequencyNoOfDays}
                    label="No of Days"
                    margin="normal"
                    name="ipBasedFrequencyNoOfDays"
                    onBlur={handleBlur}
                    onChange={(event) =>
                      setFieldValue('ipBasedFrequencyNoOfDays', event.target.value)
                    }
                    value={values.ipBasedFrequencyNoOfDays}
                    variant="outlined"
                  />
                  <Typography variant="paraText" classes="sentance">
                    per day(s).
                  </Typography>
                </Grid>
                <Grid item size={{ xs: 12 }} className="py-0 d-flex sentance-with-input">
                  <Typography variant="paraText" classes="sentance">
                    Show per Device no more than
                  </Typography>
                  <NewTextField
                    info="Maximum impressions per device"
                    className="mx-1"
                    error={Boolean(touched.userBasedFrequency && errors.userBasedFrequency)}
                    fullWidth
                    helperText={touched.userBasedFrequency && errors.userBasedFrequency}
                    label="User Frequency"
                    margin="normal"
                    name="userBasedFrequency"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('userBasedFrequency', event.target.value)}
                    value={values.userBasedFrequency}
                    variant="outlined"
                  />
                  <Typography variant="paraText" classes="sentance">
                    {' '}
                    time(s){' '}
                  </Typography>
                  <NewTextField
                    info="Duration for the allowed impressions per device"
                    className="mx-1"
                    error={Boolean(
                      touched.userBasedFrequencyNoOfDays && errors.userBasedFrequencyNoOfDays,
                    )}
                    fullWidth
                    helperText={
                      touched.userBasedFrequencyNoOfDays && errors.userBasedFrequencyNoOfDays
                    }
                    label="No of Days"
                    margin="normal"
                    name="userBasedFrequencyNoOfDays"
                    onBlur={handleBlur}
                    onChange={(event) =>
                      setFieldValue('userBasedFrequencyNoOfDays', event.target.value)
                    }
                    value={values.userBasedFrequencyNoOfDays}
                    variant="outlined"
                  />
                  <Typography variant="paraText" classes="sentance">
                    per day(s).
                  </Typography>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <div className="mt-4 d-flex justify-content-end ">
            <Button
              variant="contained"
              class="btn btn-secondary d-flex"
              onClick={() => history.push(`/advertiser/campaign`)}
            >
              Cancel
            </Button>
            <Button
              disabled={campaignData.expired}
              variant="contained"
              type="submit"
              className="btn btn-primary d-flex ml-4"
            >
              {!!campaignId
                ? tncLoader
                  ? 'Updating'
                  : 'Update'
                : tncLoader
                ? 'Creating'
                : 'Create'}
            </Button>
            {tncLoader && <OverlapLoader />}
          </div>
        </form>
      </Paper>
    </div>
  );
};
