import CookieUtil from './cookie-util';
import isEmpty from 'lodash/isEmpty';

const ADVERTISER_ID_COOKIE_NAME = 'advertiserId'; // Define the constant

const getSessionData = () => {
  const { getCookies } = CookieUtil();
  const cookies = getCookies();

  const advertiserId = cookies[ADVERTISER_ID_COOKIE_NAME] || 'defaultAdId';

  return {
    accessToken: cookies.access_token,
    refreshToken: cookies.refresh_token,
    user: !isEmpty(cookies.user) ? JSON.parse(cookies.user) : {},
    validator: cookies.validator,
    agencyId: cookies.agencyId,
    roles: cookies.roles,
    userFullName: cookies.userFullName,
    userId: cookies.userId,
    agencyCurrency: cookies.agencyCurrency,
    advertiserId: parseInt(advertiserId),
    tncUpdated: cookies.tncUpdated,
    agencyName: cookies.agencyName,
  };
};

export const setAdvertiserIdCookie = (advertiserId) => {
  const { setAppCookie } = CookieUtil();
  setAppCookie(ADVERTISER_ID_COOKIE_NAME, advertiserId);
};

export default getSessionData;
