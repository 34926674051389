import React, { useEffect, useMemo, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Grid, NewSelect, Button, NewDatePicker } from '../../common';
import Services from '../../service-utils/services';
import { useMutation, useQuery } from 'react-query';
import { GetCampaignData } from '../../service-utils/useApis';
import { useHistory, useLocation } from 'react-router-dom';

import {
  LINE_ITEM_STATUS,
  LINE_ITEM_BIDDING_STRATEGY,
  LINE_ITEM_PACING,
  GOALTYPE,
} from '../../libs/constants';
import { dateFormatMoment } from '../../components/format-date-moment';
import { getMovementFromFormat } from '../../components/format-date-moment';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import { handleMsgOnForm, getCurrencySymbol } from '../../common/utils';
import { isEmpty } from 'lodash';
import moment from 'moment';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { getSessionData } from '../../service-utils';
import { Box, FormControlLabel, IconButton, Paper, Switch, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
export const MetaData = ({
  lineItemData = '',
  payload = {},
  timezone,
  goToNextStep,
  // selectedCardData,
}) => {
  const { campaignId = '', lineItemId = '', agencyId = '', advertiserId = '' } = payload;
  const { enqueueSnackbar } = useSnackbar();
  const [timezoneOptions, setTimezoneOptions] = useState();
  const [serverErrors, setServerErrors] = useState({});
  const [cappedDaysCountFromStorage, setCappedDaysCount] = useState(0);
  const [goalTypeData, setGoalTypeData] = useState(GOALTYPE[0]);
  const history = useHistory();
  const location = useLocation();
  const { tncUpdated } = getSessionData();
  const queryParams = queryString.parse(location?.search);
  const { data: campaignData = {} } = GetCampaignData(campaignId, advertiserId, agencyId);
  const startDateTime = useMemo(() => {
    return moment(campaignData.startDateTime, 'DD/MM/yyyy HH:mm').toDate();
  }, [campaignData]);

  const endDateTime = useMemo(() => {
    return moment(campaignData.endDateTime, 'DD/MM/yyyy HH:mm').toDate();
  }, [campaignData]);

  useEffect(() => {
    const currentDateAndTime = moment();
    const timeDifference = endDateTime - currentDateAndTime;
    const numberOfDays = timeDifference / (1000 * 3600 * 24);
    const numberOfDaysRounded = Math.round(numberOfDays);
    const cappedDaysCount = numberOfDaysRounded > 8 ? 8 : numberOfDaysRounded;
    setCappedDaysCount(cappedDaysCount);
  }, [endDateTime]);

  const currencyData = getCurrencySymbol(campaignData.budget?.currency);

  const { mutate: createLineItem, isLoading: isLoadingCreateLineItem } = useMutation(
    async (values) => {
      const {
        status,
        startDateTime,
        endDateTime,
        name,
        advertiserDomain,
        impressionGoal,
        spendGoal,
        goalType,
        pacing,
        cpm,
        biddingStrategy,
      } = values;
      const payload = {
        campaignId: parseInt(campaignId),
        name: name,
        advertiserDomain,
        enabled: status?.value,
        startDateTime: dateFormatMoment(startDateTime, 'DD/MM/yyyy HH:mm'),
        endDateTime: dateFormatMoment(endDateTime, 'DD/MM/yyyy HH:mm'),
        impressionGoal: parseFloat(impressionGoal),
        spendGoal: parseFloat(spendGoal),
        pacingType: pacing?.value,
        cpm: parseFloat(cpm),
        biddingStrategy: biddingStrategy?.value,
        goalType: goalTypeData.value,
        creativeType: queryParams?.selectedType,
        isDeal: values.isDeals,
      };
      const query = queryString.stringify({ agencyId });
      const response = !!lineItemId
        ? await Services.lineItemUpdate(advertiserId, campaignId, lineItemId, query, payload)
        : await Services.lineItemCreate(advertiserId, campaignId, query, payload);
      return response.data;
    },
    {
      onSuccess: (data) => {
        const savedLineItemId = data?.lineItemDTO?.id;

        if (savedLineItemId) {
          createDeals({
            lineItemId: savedLineItemId,
            exchangeSeatPairs: formik.values.exchangeSeatPairs,
            isDeals: values.isDeals, // Make sure this is included
          });
        }
        enqueueSnackbar(
          !!lineItemId ? 'Line item updated successfully.' : 'Line item created successfully.',
          {
            variant: 'success',
          },
        );

        goToNextStep(savedLineItemId);
      },
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      name: '',
      advertiserDomain: '',
      timezone: '',
      impressionGoal: '',
      status: lineItemData?.status
        ? { label: lineItemData?.status, value: lineItemData?.status }
        : {},
      biddingStrategy: lineItemData?.biddingStrategy
        ? { label: lineItemData?.biddingStrategy, value: lineItemData?.biddingStrategy }
        : {},

      pacing: lineItemData?.pacingType
        ? { label: lineItemData?.pacingType, value: lineItemData?.pacingType }
        : {},
      spendGoal: '', // Set initial value to 0
      cpm: 0,
      startDateTime: moment().add(20, 'minutes'),
      endDateTime: moment().add(cappedDaysCountFromStorage, 'days'),
      creativeType: '',
      isDeals: false,
    },
    validationSchema: Yup.object({
      name: Yup.string().max(255).required('Name is required.'),
      impressionGoal: Yup.number().min(
        100000,
        'Impression Goal must be greater than or equal to 100000',
      ),
      spendGoal:
        currencyData === '$'
          ? Yup.number().min(50, `spend Goal must be greater than or equal to ${currencyData}50`)
          : Yup.number().min(
              5000,
              `spend Goal must be greater than or equal to ${currencyData}5000`,
            ),

      cpm: Yup.number().when('biddingStrategy', {
        is: (biddingStrategy) => biddingStrategy?.label === 'Fixed Price',
        then: () =>
          Yup.number()
            .required('CPM is required for Fixed Price strategy')
            .min(
              currencyData === '$' ? 0.5 : 40,
              `CPM must be greater than or equal to ${currencyData}${
                currencyData === '$' ? '0.5' : '40'
              }`,
            ),
        otherwise: () => Yup.number().nullable(),
      }),

      biddingStrategy: Yup.object().required('Bidding Strategy is required'),
    }),
    onSubmit: (values) => {
      if (goalTypeData.value === 'IMPRESSION_GOAL') {
        createLineItem({
          ...values,
          spendGoal: null,
        });
      } else {
        createLineItem({
          ...values,
          impressionGoal: null,
        });
      }
    },
  });

  const { data: exchangeData = [] } = useQuery(
    ['EXCHANGE_DATA', agencyId],
    async () => {
      const response = await Services.getExchangeList(queryString.stringify({ agencyId }));
      return response.data?.masterList || [];
    },
    { enabled: !!agencyId },
  );

  const { data: dealsData, isLoading: isLoadingDeals } = useQuery(
    ['GET_DEALS_DATA', lineItemId],
    async () => {
      if (!lineItemId) return [];
      const query = queryString.stringify({ agencyId });
      const response = await Services.getDealsList(advertiserId, campaignId, lineItemId, query);
      return response.data?.masterDealsList || [];
    },
    {
      enabled: !!lineItemId,
      onSuccess: (data) => {
        if (data && data.length > 0) {
          // setTimeout(() => {
          setFieldValue(
            'exchangeSeatPairs',
            data.map((deal) => ({
              exchange: { label: `Exchange ${deal.exchangeId}`, value: deal.exchangeId },
              dealId: deal.dealId,
              bidFloor: deal.bidFloor,
            })),
          );
          // }, 0);
        }
      },
    },
  );

  const exchangeOptions = useMemo(
    () => exchangeData.map((item) => ({ label: item?.label, value: item?.id })),
    [exchangeData],
  );
  const { mutate: createDeals } = useMutation(
    async (values) => {
      const payload = values.isDeals
        ? values.exchangeSeatPairs.map((pair) => ({
            exchangeId: pair.exchange?.value || null,
            dealId: pair.dealId,
            bidFloor: pair.bidFloor,
            bidCurrency: 'USD',
          }))
        : [];

      const query = queryString.stringify({ agencyId });

      return await Services.updateDealsList(
        advertiserId,
        campaignId,
        values.lineItemId,
        payload,
        query,
      );
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        //enqueueSnackbar('Deals created successfully.', { variant: 'success' });
      },
    },
  );

  const formik = useFormik({
    initialValues: {
      exchangeSeatPairs: dealsData?.length
        ? dealsData.map((deal) => {
            const exchange = exchangeData.find((ex) => ex.id === deal.exchangeId);
            return {
              exchange: {
                label: exchange?.label || `Exchange ${deal.exchangeId}`,
                value: deal.exchangeId,
              },
              dealId: deal.dealId,
              bidFloor: deal.bidFloor,
            };
          })
        : [
            {
              exchange: null,
              dealId: '',
              bidFloor: '',
            },
          ],
    },
    enableReinitialize: true, // Allows values to update when data changes
    validationSchema: Yup.object({
      exchangeSeatPairs: Yup.array().of(
        Yup.object().shape({
          exchange: values.isDeals
            ? Yup.object().nullable().required('Required')
            : Yup.mixed().nullable(),
          dealId: values.isDeals ? Yup.string().required('Required') : Yup.mixed().nullable(),
          bidFloor: values.isDeals
            ? Yup.number().positive('Must be positive').required('Required')
            : Yup.mixed().nullable(),
        }),
      ),
    }),
    onSubmit: (values) => createDeals(values),
  });

  const addExchangeSeatPair = () => {
    if (
      exchangeOptions.length > 1 &&
      formik.values.exchangeSeatPairs.length < exchangeOptions.length
    ) {
      formik.setFieldValue('exchangeSeatPairs', [
        ...formik.values.exchangeSeatPairs,
        { exchange: null, dealId: '', bidFloor: '' },
      ]);
    }
  };

  const removeExchangeSeatPair = (index) => {
    formik.setFieldValue(
      'exchangeSeatPairs',
      formik.values.exchangeSeatPairs.filter((_, i) => i !== index),
    );
  };

  const getAvailableExchangeOptions = (index) => {
    return exchangeOptions.filter(
      (option) =>
        !formik.values.exchangeSeatPairs.some(
          (pair, i) => i !== index && pair.exchange && pair.exchange.value === option.value,
        ),
    );
  };

  useEffect(() => {
    const obj = {
      value: timezone,
      name: timezone,
      label: timezone,
    };
    setTimezoneOptions([obj]);
    setFieldValue('timezone', obj);
  }, [timezone, setFieldValue]);

  useEffect(() => {
    if (!lineItemId) {
      setFieldValue('endDateTime', moment().add(cappedDaysCountFromStorage, 'days'));
    }
  }, [cappedDaysCountFromStorage, setFieldValue, lineItemId]);

  useEffect(() => {
    if (!isEmpty(lineItemData)) {
      const {
        biddingStrategy,
        cpm,
        //pacing,
        endDateTime,
        impressionGoal,
        spendGoal,
        advertiserDomain,
        name,
        startDateTime,
        pacingType,
        enabled,
        timezone,
        goalType,
        isDeal,
      } = lineItemData;

      const selectedStatus = LINE_ITEM_STATUS.filter((item) => item.value === enabled)[0];
      const selectedBiddingStrategy = LINE_ITEM_BIDDING_STRATEGY.filter(
        (item) => item.value === biddingStrategy,
      )[0];

      let timezoneObj = {
        value: timezone,
        name: timezone,
        label: timezone,
      };
      setFieldValue('startDateTime', getMovementFromFormat(startDateTime).toDate());
      setFieldValue('endDateTime', getMovementFromFormat(endDateTime).toDate());
      setFieldValue('status', selectedStatus);
      setFieldValue('biddingStrategy', selectedBiddingStrategy);
      setFieldValue('cpm', cpm);
      setFieldValue('pacing', LINE_ITEM_PACING.filter((item) => item.value === pacingType)[0]);
      if (goalType === 'IMPRESSION_GOAL') {
        setFieldValue('impressionGoal', impressionGoal);
        setFieldValue('goalType', goalType);
      } else {
        setFieldValue('spendGoal', spendGoal);
        setFieldValue('goalType', goalType);
      }
      setFieldValue('isDeals', isDeal);

      setFieldValue('name', name);
      setFieldValue('timezone', timezoneObj);
      setFieldValue('advertiserDomain', advertiserDomain);
      setTimezoneOptions([timezoneObj]);
    }
  }, [lineItemData, setFieldValue]);

  useEffect(() => {
    setServerErrors({});
  }, [values]);

  useEffect(() => {
    if (!isEmpty(lineItemData)) {
      const goalTypeValue =
        values.goalType === 'IMPRESSION_GOAL' ? 'IMPRESSION_GOAL' : 'SPEND_GOAL';
      setGoalTypeData(GOALTYPE.find((item) => item.value === goalTypeValue));
    }
  }, [lineItemData, setGoalTypeData, values.goalType]);

  const tncLoader = tncUpdated === 'true' && isLoadingCreateLineItem;
  return (
    <div className="pt-3 p-4 mn-h-200 LI-creation-tab">
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2} rowSpacing={0.1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item size={{ xs: 12, sm: 12 }} className="py-0">
            <NewTextField
              required
              info="Give suitable name for your final ad setup. e.g Maximum Reach, Sales Strategy"
              error={Boolean(touched.name && errors.name)}
              fullWidth
              context="this is line item name this is line item name this is line item name this is line item name this is line item name "
              helperText={touched.name && errors.name}
              label="Line Item Name"
              margin="normal"
              name="name"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('name', event.target.value)}
              value={values.name}
              variant="outlined"
            />
            {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
          </Grid>
          <Grid item size={{ xs: 12, sm: 12 }} className="py-0">
            <NewTextField
              required
              info="Enter your domain name. e.g yourbrand.com"
              error={Boolean(touched.advertiserDomain && errors.advertiserDomain)}
              fullWidth
              helperText={touched.advertiserDomain && errors.advertiserDomain}
              label="Advertiser domain"
              margin="normal"
              name="advertiserDomain"
              onBlur={handleBlur}
              onChange={(event) => setFieldValue('advertiserDomain', event.target.value)}
              value={values.advertiserDomain}
              variant="outlined"
            />
            {serverErrors.advertiserDomain && (
              <p className="text-danger mt-n2">{serverErrors.advertiserDomain}</p>
            )}
          </Grid>
          <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
            <NewSelect
              required
              info="Choose target clearing to let algorithm select best price to pay per impression . Choose fixed price to select maximum price you want to pay per 1000 impression"
              options={LINE_ITEM_BIDDING_STRATEGY}
              value={values.biddingStrategy}
              onChange={(obj) => setFieldValue('biddingStrategy', obj)}
              placeholder={`Bidding Strategy`}
              className="py-2"
            />
            {serverErrors.biddingStrategy && (
              <p className="text-danger mt-n3">{serverErrors.biddingStrategy}</p>
            )}
          </Grid>
          <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
            <NewSelect
              required
              info="Enable the status to start line item. Disable to pause it."
              options={LINE_ITEM_STATUS}
              value={values.status}
              onChange={(obj) => setFieldValue('status', obj)}
              placeholder={`Status`}
              className="py-2"
            />
            {serverErrors.enabled && <p className="text-danger mt-n3">{serverErrors.enabled}</p>}
          </Grid>
          <Grid item size={{ xs: 12, sm: 3 }} className="py-0">
            <NewDatePicker
              required
              info="Set valid start date and time within the campaign period start date and time"
              isSingleDate={true}
              placeholder={`Start Date`}
              initialValue={values.startDateTime}
              timePicker={true}
              onCallback={(date) => setFieldValue('startDateTime', date)}
              key={JSON.stringify({
                val: values.startDateTime,
                maxDate: endDateTime,
                minDate: startDateTime,
              })}
              maxDate={endDateTime}
              context="select date "
              minDate={values.status?.value === undefined ? new Date() : null}
              isDisabled={isEmpty(lineItemData) ? false : !lineItemData?.dateChangeAllowed}
            />
            {serverErrors.startDateTime && (
              <p className="text-danger mt-n2">
                {serverErrors.startDateTime} Campaign Start Date: {campaignData?.startDateTime}
              </p>
            )}
          </Grid>
          <Grid item size={{ xs: 12, sm: 3 }} className="py-0">
            <NewDatePicker
              required
              info="Set valid end date and time within the campaign period end date and time"
              context="select date "
              isSingleDate={true}
              placeholder={`End Date`}
              initialValue={values.endDateTime}
              timePicker={true}
              onCallback={(date) => setFieldValue('endDateTime', date)}
              key={JSON.stringify({
                val: values.endDateTime,
                maxDate: endDateTime,
                minDate: startDateTime,
              })}
              maxDate={endDateTime}
              minDate={lineItemData.expired === true ? null : new Date()}
              isDisabled={isEmpty(lineItemData) ? false : !lineItemData?.endDateChangeAllowed}
            />
            {/* {campaignData?.endDateTime && (
                <Typography variant="caption" color="textSecondary" className="ml-2">
                  Campaign End Date: {campaignData?.endDateTime}
                </Typography>
              )} */}
            {serverErrors.endDateTime && (
              <p className="text-danger mt-n2">
                {serverErrors.endDateTime} Campaign End Date: {campaignData?.endDateTime}
              </p>
            )}
          </Grid>
          {!campaignData?.userTimeZone && (
            <Grid item size={{ xs: 10, sm: 6 }} className="py-0">
              {/* <label>Timezone</label> */}
              <NewSelect
                required
                info="By default, it will take the time zone which is set for the campaign"
                options={timezoneOptions}
                value={values.timezone}
                placeholder={`Timezone`}
                className="py-2"
                isDisabled={true}
              />
            </Grid>
          )}
          <Grid item size={{ xs: 10, sm: 6 }} className="py-0">
            {/* <label>Timezone</label> */}
            <NewSelect
              required
              info="Select the goal you wish to achieve we"
              options={GOALTYPE}
              value={goalTypeData}
              onChange={(obj) => {
                setGoalTypeData(obj);
              }}
              placeholder={`Goal Type`}
              className="py-2"
              isDisabled={lineItemId}
            />
          </Grid>
          {goalTypeData.value === 'IMPRESSION_GOAL' ? (
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Select Impression goal and enter your target of impressions in line item duration e.g overall 100000 impressions i.e. 10000 impressions per day"
                error={Boolean(touched.impressionGoal && errors.impressionGoal)}
                fullWidth
                helperText={touched.impressionGoal && errors.impressionGoal}
                label="Impression Goal"
                margin="normal"
                name="impressionGoal"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('impressionGoal', event.target.value)}
                value={values.impressionGoal}
                variant="outlined"
                type="number"
                min={100000}
              />
              {serverErrors.impressionGoal && (
                <p className="text-danger mt-n2">{serverErrors.impressionGoal}</p>
              )}
            </Grid>
          ) : (
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Select spend goal and enter total amount you would spend in line item duration. e.g 10000 for 10 days i.e 1000/- spend per day "
                error={Boolean(touched.spendGoal && errors.spendGoal)}
                fullWidth
                helperText={touched.spendGoal && errors.spendGoal}
                label="Spend Goal"
                margin="normal"
                name="spendGoal"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('spendGoal', event.target.value)}
                value={values.spendGoal}
                variant="outlined"
                type="number"
                min={50000}
              />
              {serverErrors.spendGoal && (
                <p className="text-danger mt-n2">{serverErrors.spendGoal}</p>
              )}
            </Grid>
          )}
          <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
            <NewSelect
              required
              info="Select 'Uniform' pacing to evenly distribute spend/impression goals across the line item duration, or select 'ASAP' for achieving spend/impression goals as quickly as possible."
              options={LINE_ITEM_PACING}
              value={values.pacing}
              onChange={(obj) => setFieldValue('pacing', obj)}
              placeholder={`Pacing`}
              className="py-2"
            />
            {serverErrors.pacingType && (
              <p className="text-danger mt-n2">{serverErrors.pacingType}</p>
            )}
          </Grid>
          {values.biddingStrategy?.label === 'Fixed Price' ? (
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                required
                info="Cost per mille: This is for fixed price bidding strategy. Enter the amount you would pay for thousand impressions. e.g. 80 Rs. for 1000 impressions"
                error={Boolean(touched.cpm && errors.cpm)}
                fullWidth
                helperText={touched.cpm && errors.cpm}
                label="CPM"
                margin="normal"
                name="cpm"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('cpm', event.target.value)}
                value={values.cpm}
                variant="outlined"
                type="number"
              />
              {serverErrors.cpm && <p className="text-danger mt-n2">{serverErrors.cpm}</p>}
            </Grid>
          ) : (
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0"></Grid>
          )}

          <Grid item size={{ xs: 12, sm: 12 }} className="py-0">
            <Paper
              elevation={2}
              sx={{
                padding: 2,
                borderRadius: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Grid item size={{ xs: 12 }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={values.isDeals} // Ensure this is correctly bound
                      onChange={(event) => {
                        setFieldValue('isDeals', event.target.checked); // Properly update isDeals
                      }}
                      className="m-0 justify-content-center"
                    />
                  }
                  label="Deal Supported"
                />
              </Grid>
              <Grid container spacing={2} justifyContent="center">
                <Grid container size={{ xs: 12 }} justifyContent="center">
                  {formik.values.exchangeSeatPairs.map((pair, index) => (
                    <Grid
                      container
                      item
                      size={{ xs: 12 }}
                      key={index}
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Grid item size={{ xs: 12, sm: 4 }}>
                        <NewSelect
                          name={`exchangeSeatPairs[${index}].exchange`}
                          label="Exchange"
                          className="py-2"
                          options={getAvailableExchangeOptions(index)}
                          value={pair.exchange}
                          onChange={(value) =>
                            formik.setFieldValue(`exchangeSeatPairs[${index}].exchange`, value)
                          }
                          error={
                            formik.touched.exchangeSeatPairs?.[index]?.exchange &&
                            Boolean(formik.errors.exchangeSeatPairs?.[index]?.exchange)
                          }
                          helperText={
                            formik.touched.exchangeSeatPairs?.[index]?.exchange &&
                            formik.errors.exchangeSeatPairs?.[index]?.exchange
                          }
                          required={values.isDeals}
                          isDisabled={!values.isDeals}
                        />
                      </Grid>

                      <Grid item size={{ xs: 12, sm: 4 }}>
                        <NewTextField
                          fullWidth
                          name={`exchangeSeatPairs[${index}].dealId`}
                          label="Deal ID"
                          variant="outlined"
                          value={pair.dealId}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.exchangeSeatPairs?.[index]?.dealId &&
                            Boolean(formik.errors.exchangeSeatPairs?.[index]?.dealId)
                          }
                          helperText={
                            formik.touched.exchangeSeatPairs?.[index]?.dealId &&
                            formik.errors.exchangeSeatPairs?.[index]?.dealId
                          }
                          required={values.isDeals}
                          disabled={!values.isDeals}
                        />
                      </Grid>

                      <Grid item size={{ xs: 12, sm: 4 }}>
                        <NewTextField
                          fullWidth
                          name={`exchangeSeatPairs[${index}].bidFloor`}
                          label="Bid Floor ($)"
                          type="number"
                          variant="outlined"
                          value={pair.bidFloor}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.exchangeSeatPairs?.[index]?.bidFloor &&
                            Boolean(formik.errors.exchangeSeatPairs?.[index]?.bidFloor)
                          }
                          helperText={
                            formik.touched.exchangeSeatPairs?.[index]?.bidFloor &&
                            formik.errors.exchangeSeatPairs?.[index]?.bidFloor
                          }
                          disabled={!values.isDeals}
                          required={values.isDeals}
                        />
                      </Grid>

                      <Grid item size={{ xs: 12, sm: 2 }} container justifyContent="center">
                        <Box display="flex" gap={1} justifyContent="center">
                          {index === formik.values.exchangeSeatPairs.length - 1 &&
                            formik.values.exchangeSeatPairs.length < exchangeOptions.length &&
                            getAvailableExchangeOptions(index).length > 0 && (
                              <Tooltip title="Add Exchange">
                                <IconButton
                                  color="primary"
                                  onClick={addExchangeSeatPair}
                                  disabled={!values.isDeals}
                                >
                                  <AddCircleOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            )}

                          {formik.values.exchangeSeatPairs.length > 1 &&
                            index === formik.values.exchangeSeatPairs.length - 1 && (
                              <Tooltip title="Remove Exchange">
                                <IconButton
                                  disabled={!values.isDeals}
                                  color="error"
                                  onClick={() => removeExchangeSeatPair(index)}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            )}
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
        <div className="mt-4 d-flex justify-content-end ">
          <Button variant="contained" className="btn btn-secondary d-flex" onClick={history.goBack}>
            Cancel
          </Button>
          {!!lineItemId && (
            <Button
              variant="contained"
              className="btn btn-secondary d-flex ml-4"
              disabled={lineItemData.expired}
              onClick={() => {
                goToNextStep(lineItemId);
              }}
            >
              Next
            </Button>
          )}
          <Button
            variant="contained"
            type="submit"
            disabled={lineItemData.expired}
            className="btn btn-primary d-flex ml-4"
          >
            {!!lineItemId
              ? tncLoader
                ? 'Updating'
                : 'Update And Next'
              : tncLoader
              ? 'Creating'
              : 'Create And Next'}
          </Button>
        </div>
        {tncLoader && <OverlapLoader />}
      </form>
    </div>
  );
};
