import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { NewTextField, Grid, Button, Typography } from '../../common';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { useMutation, useQuery } from 'react-query';
import Services from '../../service-utils/services';
import history from '../../history';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import { getSessionData } from '../../service-utils';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { dateFormatMomentWithoutTime } from '../../components/format-date-moment';

const ApplyVoucher = ({ refetchGetBalance, handleBackButton }) => {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [serverErrors, setServerErrors] = useState({});
  const { agencyId, tncUpdated } = getSessionData();
  const queryParams = queryString.parse(location.search);
  const { voucherCode: urlVoucherCode } = queryParams;
  const isMounted = React.useRef(true);

  const formik = useFormik({
    initialValues: {
      inputVoucherCode: urlVoucherCode || '',
    },
    onSubmit: (values) => {
      applyVoucher(values);
    },
  });

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = formik;

  const { data: voucherData } = useQuery(
    ['VOUCHER_DATA', agencyId, urlVoucherCode || values.inputVoucherCode],
    async () => {
      const queryParams = { agencyId };

      const response = await Services.getVoucherDetails(
        agencyId,
        urlVoucherCode || values.inputVoucherCode,
        queryString.stringify(queryParams),
      );
      return response?.data?.voucher;
    },
    { enabled: !!agencyId && !!(urlVoucherCode || values.inputVoucherCode) },
  );

  const { mutate: applyVoucher, isLoading: isLoadingApplyVoucher } = useMutation(
    async ({ inputVoucherCode }) => {
      const queryParams = { agencyId };

      const response = await Services.agencyApplyVoucher(
        agencyId,
        inputVoucherCode,
        queryString.stringify(queryParams),
      );
      return response.data;
    },
    {
      onError: (err) => {
        if (isMounted.current) {
          handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
        }
      },
      onSuccess: () => {
        if (isMounted.current) {
          enqueueSnackbar('Voucher Applied successfully.', { variant: 'success' });
          history.push(`/agency-billing/account#1`);
          handleBackButton();
          refetchGetBalance();
        }
      },
    },
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setServerErrors({});
  }, [values]);

  useEffect(() => {
    if (voucherData?.voucherCode && voucherData.voucherCode !== values.inputVoucherCode) {
      setFieldValue('inputVoucherCode', voucherData.voucherCode);
    }
  }, [voucherData, setFieldValue, values.inputVoucherCode]);

  const tncLoader = tncUpdated === 'true' && isLoadingApplyVoucher;

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="p-2">
        <form onSubmit={handleSubmit}>
          <Grid container rowSpacing={0.1} spacing={1}>
            <Grid item size={{ xs: 12 }}>
              <div className="d-flex justify-content-center align-items-center my-3">
                <Typography>Apply Voucher</Typography>
              </div>
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <NewTextField
                required
                variant="outlined"
                label="Enter Voucher Code"
                margin="normal"
                fullWidth
                name="inputVoucherCode"
                value={values.inputVoucherCode}
                onChange={(event) => setFieldValue('inputVoucherCode', event.target.value)}
                onBlur={handleBlur}
                error={touched.inputVoucherCode && !!errors.inputVoucherCode}
                helperText={touched.inputVoucherCode && errors.inputVoucherCode}
              />
              {voucherData?.validity && (
                <Typography variant="caption" color="textSecondary" className="ml-2">
                  Expires on: {dateFormatMomentWithoutTime(voucherData.validity)}
                </Typography>
              )}
            </Grid>
          </Grid>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <Button
              type="submit"
              className="btn btn-primary mt-2 w-40 button-container"
              size="medium"
              disabled={tncLoader}
            >
              {tncLoader ? 'Applying...' : 'Apply'}
            </Button>
          </div>
        </form>
        {tncLoader && <OverlapLoader />}
      </div>
    </div>
  );
};

export default ApplyVoucher;
