import React, { useEffect, useMemo, useState, useRef } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { SearchInput, Table } from '../../components';
import { Button, Loader, NewSelect } from '../../common';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
// import { Dropdown } from 'react-bootstrap';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSnackbar } from 'notistack';
import Chip from '@mui/material/Chip';
import { ConformationBox } from '../../components/conformationBox';
import { commonApiError, getCurrencySymbol } from '../../common/utils';
import { dateFormatMoment } from '../../components/format-date-moment';
import {
  AccountButton,
  DeleteButton,
  DisableButton,
  EnableButton,
  SearchButton,
} from '../../components/react-icons/ReactIcons';
import { AGENCY_STATUS } from '../../libs/constants';
import useDebounce from '../../components/debounce-hook';
import { getSessionData } from '../../service-utils';

export const Agency = () => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const location = useLocation();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const [deleteId, setDeleteId] = useState();
  const [disabledId, setDisabledId] = useState();
  const [enableId, setEnableId] = useState();
  const prevPageNumberRef = useRef(null);
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState(AGENCY_STATUS[0]);
  const debouncedValue = useDebounce(searchText, 500);
  const { agencyId } = getSessionData();
  const [selectedAgencyName, setSelectedAgencyName] = useState(null);
  const {
    data,
    isLoading,
    refetch: refetchAgencies,
  } = useQuery(['AGENCIES_DATA', pageNumber, pageLimit], async () => {
    const queryStringParams = {
      name: debouncedValue,
      agencyId,
      enabled: status.value,
      pageNum: pageNumber - 1,
      pageSize: pageLimit,
    };
    const response = await Services.getAllAencies(queryString.stringify(queryStringParams));
    return response.data;
  });

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Agencies
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const handleAccountClick = (id) => {
    const selectedAgency = data?.agencies.find((agency) => agency.id === id);
    if (selectedAgency) {
      const { agencyName } = selectedAgency;
      setSelectedAgencyName(agencyName);
      history.push(`/agency-billing/account?agencyId=${id}&agencyName=${agencyName}`);
    }
  };

  useEffect(() => {
    const queryParams = queryString.parse(location.search);
    const parsedPageNum = Number(queryParams.pageNum);
    if (!isNaN(parsedPageNum) && parsedPageNum + 1 !== prevPageNumberRef.current) {
      setPageNumber(parsedPageNum + 1);
      prevPageNumberRef.current = parsedPageNum + 1;
    }
    setPageLimit(Number(queryParams.pageSize) || 10);
  }, [setPageLimit]);

  useEffect(() => {
    const queryParams = {
      pageNum: pageNumber - 1,
      pageSize: pageLimit,
    };
    history.replace({ search: queryString.stringify(queryParams) });
  }, [pageNumber, pageLimit, history]);

  const { mutate: handleDeleteAgency } = useMutation(
    async (agencyId) => {
      const response = await Services.agencyDelete(agencyId);
      return response.data;
    },
    {
      onError: (err, data) => {
        enqueueSnackbar(commonApiError, { variant: 'error' });
      },
      onSuccess: (a, b) => {
        //console.log(a,b);
        refetchAgencies();
        setDeleteId();
        enqueueSnackbar('Agency deleted successfully.', { variant: 'success' });
      },
    },
  );

  const { mutate: handleStatusChange } = useMutation(
    async (payload) => {
      const { agencyId, enabled } = payload;
      const response = await Services.agencyStatusUpdate(agencyId, enabled);
      return response.data;
    },
    {
      onError: (err, data) => {
        //console.log('err',err,data)
        enqueueSnackbar('Error in updating status.', { variant: 'error' });
      },
      onSuccess: (a, b) => {
        //console.log(a,b)
        refetchAgencies();
        setDisabledId();
        setEnableId();
        enqueueSnackbar('Updated status successfully.', { variant: 'success' });
      },
    },
  );

  const tableData = useMemo(() => {
    return data?.agencies || [];
  }, [data]);

  // const { mutate: crateAdmin } = useMutation(
  //   async (agencyId) => {
  //     const response = await Services.agencyCreateAdmin(agencyId);
  //     return response.data;
  //   },
  //   {
  //     onError: (err, data) => {
  //       enqueueSnackbar('Error in creating admin.', { variant: 'error' });
  //     },
  //     onSuccess: (a, b) => {
  //       //console.log(a,b)
  //       refetchAgencies();
  //       enqueueSnackbar('Create admin successfully.', { variant: 'success' });
  //     },
  //   },
  // );
  const handleIcons = (row) => {
    const { id, enabled } = row;
    return (
      <div className="flex items-center gap-2">
        <AccountButton handleApply={() => handleAccountClick(id)} />
        {!enabled ? (
          <EnableButton handleToggle={() => setEnableId(id)} />
        ) : (
          <DisableButton handleToggle={() => setDisabledId(id)} />
        )}
        <DeleteButton handleDelete={() => setDeleteId(id)} />
      </div>
    );
  };
  // const manageButton = (row) => {
  //   const { id: agencyId } = row;
  //   return (
  //     <>
  //       <Dropdown id="dropdown-item-button" className="action-dropdown btn-action">
  //         <Dropdown.Toggle variant="link">
  //           <MoreHorizIcon />
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu>
  //           {!row?.isAdminCreated && (
  //             <Dropdown.Item onClick={() => crateAdmin(agencyId)}>Create Admin</Dropdown.Item>
  //           )}
  //           <Dropdown.Item onClick={() => handleAccountClick(agencyId)}>Account</Dropdown.Item>
  //           {!row.enabled && (
  //             <Dropdown.Item onClick={() => setEnableId(agencyId)}>Enable Agency</Dropdown.Item>
  //           )}
  //           {row.enabled && (
  //             <Dropdown.Item onClick={() => setDisabledId(agencyId)}>Disable Agency</Dropdown.Item>
  //           )}
  //           <Dropdown.Item onClick={() => setDeleteId(agencyId)}>Delete</Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </>
  //   );
  // };

  const getTableHeader = [
    {
      dataField: 'agencyName',
      text: 'Agency Name',
      style: {
        width: '15%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'agencyType',
      text: 'Agency Type',
      style: {
        width: '10%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'contactNo',
      text: 'Contact No',
      style: {
        width: '10%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'contactEmail',
      text: 'Email ID',
      style: {
        width: '15%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'createdDate',
      text: 'Created Date',
      formatter: (col, row) => dateFormatMoment(col, 'DD MMM YY'),
      style: {
        width: '10%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'currentBalance',
      text: 'Current Balance',
      formatter: (col, row) =>
        `${getCurrencySymbol(row?.currency)} ${row?.currentBalance.toFixed(2)}`,
      style: {
        width: '15%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) =>
        col ? (
          <Chip label="Enabled" className="alert-success" />
        ) : (
          <Chip label="Disabled" className="alert-danger" />
        ),
      style: {
        width: '10%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
    // {
    //   dataField: 'isAdminCreated',
    //   text: 'Admin Present',
    // },
    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => manageButton(row),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => handleIcons(row),
      style: {
        width: '15%',
        position: 'sticky',
        left: '0',
        zIndex: '1',
        overflow: 'hidden',
        tableLayout: 'fixed',
        wordBreak: 'break-all',
      },
    },
  ];
  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <NewSelect
            required
            options={AGENCY_STATUS}
            value={status}
            onChange={(obj) => setStatus(obj)}
            placeholder={`Status`}
            isMulti={false}
            // info="hello"
            className="mt-n3 ml-3"
          />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3 btn-add"
            startIcon={<SearchButton />}
            onClick={() => refetchAgencies()}
          >
            Apply
          </Button>
        </div>
      </div>

      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'name',
              order: 'asc',
            },
          ]}
        />
      )}

      <ConformationBox
        isOpen={!!disabledId}
        handleClose={() => setDisabledId()}
        handleSubmit={() => handleStatusChange({ agencyId: disabledId, enabled: false })}
        title="Disable ?"
        subtitle="Are you sure you want to disable this agency?"
        btnCloseLabel="Close"
        btnSubmitLabel="Disable"
      />
      <ConformationBox
        isOpen={!!enableId}
        handleClose={() => setEnableId()}
        handleSubmit={() => handleStatusChange({ agencyId: enableId, enabled: true })}
        title="Enable ?"
        subtitle="Are you sure you want to enable this agency?"
        btnCloseLabel="Close"
        btnSubmitLabel="Enable"
      />
      <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDeleteAgency(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this agency?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      />
    </>
  );
};
