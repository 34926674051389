import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Container,
  useMediaQuery,
  useTheme,
  IconButton,
  Fade,
  styled,
} from '@mui/material';
import {
  ChevronRight as ChevronRightIcon,
  ArrowBack as ArrowBackIcon,
  BusinessCenter as BusinessIcon,
  AttachMoney as MoneyIcon,
  Person as PersonIcon,
} from '@mui/icons-material';
import history from '../../history';
import logo from '../../assets/img/adzy.png';
import Services from '../../service-utils/services';
import { CookieUtil, getSessionData } from '../../service-utils';
import OverlapLoader from '../../common/loader/OverlapLoader';
import auth from '../../common/auth';

// Custom styled components
const PageWrapper = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  backgroundColor: '#f8f9fb',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(4),
  },
}));

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
  [theme.breakpoints.up('sm')]: {
    marginBottom: theme.spacing(6),
  },
}));

const LogoImage = styled('img')(({ theme }) => ({
  height: '40px',
  marginBottom: theme.spacing(2),
  [theme.breakpoints.up('sm')]: {
    height: '50px',
    marginBottom: theme.spacing(3),
  },
}));

const AgencyCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: theme.spacing(1.5),
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  marginBottom: theme.spacing(2),
  boxShadow: '0 6px 14px rgba(0,0,0,0.06)',
  transition: 'all 0.3s ease',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 20px rgba(0,0,0,0.1)',
  },
  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(2),
  },
}));

const AgencyHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.up('sm')]: {
    marginBottom: 0,
    width: 'auto',
  },
}));

const AgencyIcon = styled(Box)(({ theme }) => ({
  width: '42px',
  height: '42px',
  borderRadius: '50%',
  backgroundColor: '#dd6633',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginRight: theme.spacing(2),
  flexShrink: 0,
  [theme.breakpoints.up('sm')]: {
    width: '60px',
    height: '60px',
    marginRight: theme.spacing(3),
  },
}));

const InfoTagsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
  [theme.breakpoints.up('sm')]: {
    marginTop: 0,
  },
}));

const InfoTag = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'rgba(0,0,0,0.04)',
  padding: '2px 8px',
  borderRadius: '20px',
  fontSize: '0.75rem',
  [theme.breakpoints.up('sm')]: {
    padding: '4px 10px',
    fontSize: '0.8rem',
  },
}));

const ActionButtons = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1.5),
  alignSelf: 'flex-end',
  width: '100%',
  justifyContent: 'flex-end',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
    marginTop: 0,
    marginLeft: 'auto',
  },
}));

const EmptyState = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(4),
  backgroundColor: '#ffffff',
  borderRadius: theme.spacing(1.5),
  boxShadow: '0 6px 14px rgba(0,0,0,0.06)',
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    padding: theme.spacing(6),
    borderRadius: theme.spacing(2),
  },
}));

const BackButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  borderRadius: '8px',
  textTransform: 'none',
  fontWeight: 500,
  padding: '8px 16px',
  [theme.breakpoints.up('sm')]: {
    marginTop: theme.spacing(4),
  },
}));

const AgencySelection = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { userId } = getSessionData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [agencies, setAgencies] = useState([]);
  const { setAgencySelectData } = auth();

  const { data, isLoading, isError, refetch } = useQuery(
    ['USER_ROLE_LIST', userId],
    async () => {
      const response = await Services.getUserRoleList(userId);
      return response.data;
    },
    {
      enabled: !!userId,
      retry: 1,
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Failed to fetch agency data';
        //enqueueSnackbar(errorMsg, { variant: 'error' });
      },
    },
  );
  const logout = () => {
    const { removeAllCookie } = CookieUtil();
    removeAllCookie();
    window.location.replace(`${window.location.origin}/login`);
  };

  const { mutate: handleOnSubmit, isLoading: isLoginLoading } = useMutation(
    async ({ agencyId, inviteStatus }) => {
      return await Services.userInvitionAccept(agencyId, inviteStatus);
    },
    {
      onSuccess: (data, variables) => {
        enqueueSnackbar(
          variables.inviteStatus === 'ACCEPT' ? 'Invitation Accepted' : 'Invitation Rejected',
          { variant: 'success' },
        );
        refetch();
      },
      onError: () => {
        enqueueSnackbar('Action failed. Please try again.', { variant: 'error' });
      },
    },
  );

  useEffect(() => {
    if (data && data.success && data.userDTO.userRoleList) {
      const agencyData = data.userDTO.userRoleList.map((role) => ({
        agencyId: role.agencyId,
        agencyName: role.agencyName,
        role: role.role,
        agencyCurrency: role.agencyCurrency,
        status: role.status || '',
        id: role.id || role.agencyId,
      }));
      setAgencies(agencyData);
    }
  }, [data]);

  const handleAgencySelect = (agency) => {
    if (!agency || !agency.agencyId) {
      enqueueSnackbar('Invalid agency selected.', { variant: 'error' });
      return;
    }

    try {
      setAgencySelectData({ agency });
      setTimeout(() => {
        history.replace('/dashboard');
      }, 300);
    } catch (error) {
      console.error('Error selecting agency:', error);
      enqueueSnackbar('Failed to select agency. Please try again.', { variant: 'error' });
    }
  };

  const backToLogin = () => {
    logout();
    history.replace('/login');
  };

  const handleInvitation = (agency, status) => {
    handleOnSubmit({
      agencyId: agency.id,
      inviteStatus: status,
    });
  };

  return (
    <PageWrapper>
      <Container maxWidth="sm" sx={{ width: '100%' }}>
        <Header>
          <LogoImage
            src="https://content.adzylytics.com/static/images/adzylytics_logo.png"
            alt="Adzy Logo"
          />
          <Typography
            variant={isMobile ? 'body2' : 'body1'}
            color="text.secondary"
            align="center"
            sx={{ mt: 1, maxWidth: isMobile ? '95%' : '80%' }}
          >
            Choose an agency to access the dashboard and start working
          </Typography>
        </Header>

        {isLoading ? (
          <EmptyState>
            <CircularProgress size={isMobile ? 40 : 48} thickness={4} />
            <Typography
              variant={isMobile ? 'body1' : 'subtitle1'}
              sx={{ mt: 3, color: 'text.secondary' }}
            >
              Loading your agencies...
            </Typography>
          </EmptyState>
        ) : isError ? (
          <EmptyState>
            <Box
              sx={{
                width: isMobile ? 50 : 60,
                height: isMobile ? 50 : 60,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'error.light',
                color: 'error.main',
                mb: 2,
              }}
            >
              <Typography variant={isMobile ? 'h6' : 'h5'}>!</Typography>
            </Box>
            <Typography variant={isMobile ? 'subtitle1' : 'h6'} color="error.main">
              Connection Error
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              align="center"
              sx={{ mt: 1, mb: 3, maxWidth: isMobile ? '95%' : '80%' }}
            >
              We couldn't retrieve your agency list. Please try again later.
            </Typography>
            <Button
              variant="contained"
              disableElevation
              startIcon={<ArrowBackIcon />}
              onClick={() => history.replace('/login')}
              sx={{ borderRadius: 2, textTransform: 'none', py: isMobile ? 0.75 : 1 }}
            >
              Back to Login
            </Button>
          </EmptyState>
        ) : (
          <>
            {agencies.length > 0 ? (
              <Fade in timeout={500}>
                <Box width="100%">
                  {agencies.map((agency, index) => (
                    <Fade in timeout={500 + index * 100} key={agency.agencyId}>
                      <AgencyCard>
                        <AgencyHeader>
                          <AgencyIcon>
                            <BusinessIcon sx={{ color: '#fff', fontSize: isMobile ? 20 : 28 }} />
                          </AgencyIcon>

                          <Box sx={{ flexGrow: 1 }}>
                            <Typography
                              variant={isMobile ? 'subtitle1' : 'h6'}
                              fontWeight="600"
                              noWrap
                            >
                              {agency.agencyName}
                            </Typography>

                            <InfoTagsContainer>
                              <InfoTag>
                                <PersonIcon sx={{ fontSize: isMobile ? 14 : 16, mr: 0.5 }} />
                                {agency.role}
                              </InfoTag>

                              <InfoTag>
                                <MoneyIcon sx={{ fontSize: isMobile ? 14 : 16, mr: 0.5 }} />
                                {agency.agencyCurrency}
                              </InfoTag>
                            </InfoTagsContainer>
                          </Box>
                        </AgencyHeader>

                        <ActionButtons>
                          {agency.status && agency.status.toLowerCase() === 'invited' ? (
                            <>
                              <Button
                                variant="outlined"
                                color="success"
                                size={isMobile ? 'small' : 'medium'}
                                onClick={() => handleInvitation(agency, 'ACCEPT')}
                                sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                              >
                                Accept
                              </Button>
                              <Button
                                variant="outlined"
                                color="error"
                                size={isMobile ? 'small' : 'medium'}
                                onClick={() => handleInvitation(agency, 'REJECT')}
                                sx={{ fontSize: isMobile ? '0.75rem' : '0.875rem' }}
                              >
                                Reject
                              </Button>
                            </>
                          ) : (
                            <IconButton onClick={() => handleAgencySelect(agency)}>
                              <ChevronRightIcon />
                            </IconButton>
                          )}
                        </ActionButtons>
                      </AgencyCard>
                    </Fade>
                  ))}
                </Box>
              </Fade>
            ) : (
              <EmptyState>
                <Box
                  sx={{
                    width: isMobile ? 50 : 60,
                    height: isMobile ? 50 : 60,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: theme.palette.grey[100],
                    mb: 2,
                  }}
                >
                  <BusinessIcon color="disabled" sx={{ fontSize: isMobile ? 24 : 28 }} />
                </Box>
                <Typography variant={isMobile ? 'subtitle1' : 'h6'} color="text.primary">
                  No Agencies Found
                </Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                  align="center"
                  sx={{ mt: 1, maxWidth: isMobile ? '95%' : '80%' }}
                >
                  You don't have access to any agencies. Please contact your administrator.
                </Typography>
              </EmptyState>
            )}

            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
              <BackButton
                className="primary-color"
                startIcon={<ArrowBackIcon />}
                onClick={backToLogin}
                size={isMobile ? 'small' : 'medium'}
              >
                Back to Login
              </BackButton>
            </Box>
          </>
        )}
      </Container>

      {isLoginLoading && <OverlapLoader />}
    </PageWrapper>
  );
};

export default AgencySelection;
