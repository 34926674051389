import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup'; // For form validation
import { NewTextField, Grid, Button } from '../../common';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { useMutation } from 'react-query';
import Services from '../../service-utils/services';
import history from '../../history';
import { useSnackbar } from 'notistack';
import { getCurrencySymbol, handleMsgOnForm } from '../../common/utils';
import { ConformationBox } from '../../components/conformationBox';
import { getSessionData } from '../../service-utils';
import queryString from 'query-string';

const RefundBalance = ({
  currency,
  userAgencyId,
  agencyName,
  handleBackButton,
  refetchGetBalance,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { agencyId } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});
  const [isConfirmationOpen, setConfirmationOpen] = useState(false); // State to control ConfirmationBox visibility
  const [formValues, setFormValues] = useState(null); // Store form values for confirmation
  const { tncUpdated } = getSessionData();
  const { mutate: Refund, isLoading: isLoadingRefund } = useMutation(
    async (values) => {
      const { txnId, note, amount } = values;
      const queryParams = { agencyId };

      const payload = {
        amount: {
          amount: parseFloat(amount),
          currency: currency,
        },
        notes: note,
        txnRef: txnId,
      };
      const response = await Services.agencyRefund(
        userAgencyId,
        payload,
        queryString.stringify(queryParams),
      );
      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: () => {
        history.push(`/agency-billing/account?agencyId=${userAgencyId}&agencyName=${agencyName}#1`);
        enqueueSnackbar('Refund successfully.', { variant: 'success' });
        handleBackButton();
        refetchGetBalance();
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      txnId: '',
      amount: '',
      note: '',
    },
    validationSchema: Yup.object({
      amount: Yup.number().required('Amount is required').positive('Amount must be greater than 0'),
      txnId: Yup.string().required('Transaction Id is required'),
      note: Yup.string().required('Reason is required'),
    }),
    onSubmit: (values) => {
      setFormValues(values); // Save form values
      setConfirmationOpen(true); // Open the confirmation box
    },
  });

  useEffect(() => {
    setServerErrors({});
  }, [values]);

  const handleConfirmSubmit = () => {
    setConfirmationOpen(false);
    Refund(formValues); // Use saved form values to initiate the refund process
  };

  const handleCancelConfirm = () => {
    setConfirmationOpen(false); // Close the confirmation box without submitting
  };
  const tncLoader = tncUpdated === 'true' && isLoadingRefund;

  return (
    <div className="d-flex justify-content-between align-items-center">
      <div className="p-2">
        <form onSubmit={handleSubmit}>
          <Grid container spacing={1}>
            <Grid item size={{ xs: 12 }}>
              <NewTextField
                required
                variant="outlined"
                label="Enter Amount"
                margin="normal"
                type="number"
                fullWidth
                name="amount"
                value={values.amount}
                onChange={(event) => setFieldValue('amount', event.target.value)}
                onBlur={handleBlur}
                error={touched.amount && !!errors.amount}
                helperText={touched.amount && errors.amount}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <NewTextField
                required
                variant="outlined"
                label="Transaction Id"
                margin="normal"
                fullWidth
                name="txnId"
                value={values.txnId}
                onChange={(event) => setFieldValue('txnId', event.target.value)}
                onBlur={handleBlur}
                error={touched.txnId && !!errors.txnId}
                helperText={touched.txnId && errors.txnId}
              />
            </Grid>
            <Grid item size={{ xs: 12 }}>
              <NewTextField
                required
                variant="outlined"
                label="Reason"
                margin="normal"
                fullWidth
                name="note"
                value={values.note}
                onChange={(event) => setFieldValue('note', event.target.value)}
                onBlur={handleBlur}
                error={touched.note && !!errors.note}
                helperText={touched.note && errors.note}
              />
            </Grid>
          </Grid>
          <div className="d-flex justify-content-center align-items-center mt-3">
            <Button
              type="submit"
              className="btn btn-primary mt-2 w-40 button-container"
              size="medium"
              disabled={tncLoader}
            >
              {tncLoader ? 'Submitting...' : 'Submit'}
            </Button>
          </div>
        </form>
        {tncLoader && <OverlapLoader />}
      </div>
      <ConformationBox
        isOpen={isConfirmationOpen}
        handleClose={handleCancelConfirm}
        handleSubmit={handleConfirmSubmit}
        title={`Refund Amount :- ${getCurrencySymbol(currency)} ${values.amount}`}
        subtitle={`Are you sure you want to proceed with the refund?`}
        btnCloseLabel="Cancel"
        btnSubmitLabel="Confirm"
      />
    </div>
  );
};

export default RefundBalance;
