import React from 'react';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { InputLabel, Typography } from '@mui/material';
import './inputElements.scss';
import moment from 'moment';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
export const NewDateRangePicker = (props) => {
  const { minDate, maxDate, info, onCallback, initialRange, timePicker } = props;

  const rangeStart = initialRange?.[0] ? moment(initialRange[0]).toDate() : moment().toDate();
  const rangeEnd = initialRange?.[1]
    ? moment(initialRange[1]).toDate()
    : moment().add(15, 'days').toDate();

  return (
    <div className="date-range-picker">
      <InputLabel className="mb-0 label">
        <div className="d-flex align-items-center">
          {props?.placeholder || 'Select Date Range'}
          {props?.required && (
            <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
              {' '}
              *
            </span>
          )}
          {props?.info && (
            <Tooltip
              title={<Typography className="TooltipFontSize">{props?.info}</Typography>}
              arrow
            >
              <IconButton aria-label="info" size="small" className="info-icon">
                <InfoOutlinedIcon className="infoIcon" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </InputLabel>
      <DateRangePicker
        initialSettings={{
          timePicker: props?.timePicker,
          timePicker24Hour: true,
          showDropdowns: true,
          startDate: rangeStart,
          endDate: rangeEnd,
          locale: {
            // format: !props?.timePicker ? 'D MMM YYYY' : 'D MMM YYYY HH:mm',
            format: !props?.timePicker ? 'D MMM YYYY' : 'D MMM YYYY',
          },
          ...(props.minDate ? { minDate: props.minDate } : {}),
          ...(props.maxDate ? { maxDate: props.maxDate } : {}),
        }}
        autoUpdateInput={true}
        startDate={rangeStart}
        endDate={rangeEnd}
        onCallback={(start, end) => props.onCallback(start, end)} // Pass the selected dates to the parent component
        {...props}
      >
        <input type="text" className="form-control" />
      </DateRangePicker>
    </div>
  );
};

export const NewDatePicker = (props) => {
  const rangeStart = props?.initialValue ? props?.initialValue : moment().toDate();
  const { minDate, maxDate, info } = props;

  return (
    <div className="date-range-picker">
      <InputLabel className="mb-0 label">
        <div className="d-flex align-items-center">
          {props?.placeholder || 'Select Date'}
          {props?.required && (
            <span aria-hidden="true" className="MuiFormLabel-asterisk MuiInputLabel-asterisk">
              {' '}
              *
            </span>
          )}
          {info && (
            <Tooltip title={<Typography className="TooltipFontSize">{info}</Typography>} arrow>
              <IconButton aria-label="info" size="small" className="info-icon">
                <InfoOutlinedIcon className="infoIcon" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      </InputLabel>
      <DateRangePicker
        initialSettings={{
          singleDatePicker: true,
          timePicker: props?.timePicker,
          timePicker24Hour: true,
          showDropdowns: true,
          startDate: rangeStart,
          minDate,
          maxDate,
          locale: {
            format: !props?.timePicker ? 'D MMM YYYY' : 'D MMM YYYY HH:mm',
          },
        }}
        autoUpdateInput={true}
        startDate={rangeStart}
        {...props}
      >
        <input type="text" className="form-control" disabled={props?.isDisabled} />
      </DateRangePicker>
    </div>
  );
};
