import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useSnackbar } from 'notistack';
import { USER_STATUS, USER_ROLE } from '../../libs/constants';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';

export const UserCreate = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { agencyId, roles } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});
  const { tncUpdated } = getSessionData();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(
      setHeader({
        back: {
          text: 'Back',
        },
      }),
    );
  }, [dispatch]);

  const { data: userData = {} } = useQuery(
    ['USER_DATA', userId],
    async () => {
      const response = await Services.getUserDetails(userId, agencyId);
      return response?.data?.userDTO;
    },
    { enabled: !!userId && !!agencyId },
  );

  const { data: advertisersData = [], isLoading: isLoadingAllAdv } = useQuery(
    ['ALL_ADVERTISERS', agencyId],
    async () => {
      const queryStringParams = { pageSize: 5000, pageNum: 0, agencyId };
      const response = await Services.advertisersGetAll(queryString.stringify(queryStringParams));
      return response.data?.advertiserList || [];
    },
    { enabled: !!agencyId },
  );

  const advertiserOptions = useMemo(() => {
    const advOptions = advertisersData.map((adv) => ({
      label: adv.name,
      value: adv.id,
    }));

    return [{ label: 'All Advertisers', value: 'ALL' }, ...advOptions];
  }, [advertisersData]);
  const { mutate: createUser, isLoading: isLoadingCreateUser } = useMutation(
    async (values) => {
      const { name, email, password, status, role, advertiserList } = values;
      let payload = {};

      if (userId) {
        payload = {
          name,
          status: status.value,
          role: role?.value,
          advertiserIds: advertiserList.includes('ALL') ? [] : advertiserList,
        };
      } else {
        payload = {
          name,
          email,
          password,
          status: status.value,
          role: role?.value,
          agencyId: parseInt(agencyId),
          advertiserId: advertiserList.includes('ALL') ? [] : advertiserList,
        };
      }
      const query = queryString.stringify({ agencyId });
      const { data } = !!userId
        ? await Services.userUpdate(userId, query, payload)
        : await Services.userCreate(query, payload);
      return data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        if (!!userId) {
          enqueueSnackbar('User updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('User created successfully.', { variant: 'success' });
        }
        history.push(`/user`);
      },
    },
  );

  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      name: '',
      password: '',
      rePassword: '',
      role: USER_ROLE[2],
      status: USER_STATUS[0],
      advertiserList: [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .min(2, 'Must be more than 1 characters')
        .max(255, 'Must be less than 255 characters')
        .required('Name is required.'),
      email: Yup.string().email('Invalid email address').required('Email is required.'),
      password: !userId ? Yup.string().required('Password is required.') : Yup.string(),
      rePassword: Yup.string().when('password', {
        is: (val) => !userId || val,
        then: Yup.string()
          .required('Confirm Password is required.')
          .oneOf([Yup.ref('password')], 'Passwords must match.'),
        otherwise: Yup.string(),
      }),
    }),
    onSubmit: () => createUser(values),
  });

  const handleAdvertiserChange = (selectedOptions) => {
    const options = Array.isArray(selectedOptions)
      ? selectedOptions
      : selectedOptions
      ? [selectedOptions]
      : [];

    const hasAll = options.some((opt) => opt.value === 'ALL');

    if (hasAll) {
      setFieldValue('advertiserList', ['ALL']);
    } else {
      const filteredOptions = options.filter((opt) => opt.value !== 'ALL');
      setFieldValue(
        'advertiserList',
        filteredOptions.map((opt) => opt.value),
      );
    }
  };

  useEffect(() => {
    if (!isEmpty(userData)) {
      const getStatus =
        USER_STATUS.filter((item) => item.value === Number(userData.status))[0] || USER_STATUS[0];

      userData?.email && setFieldValue('email', userData.email);
      userData?.name && setFieldValue('name', userData.name);
      !isEmpty(getStatus) && setFieldValue('status', getStatus);

      if (userData?.userRoleList && userData.userRoleList.length > 0) {
        const userRole = userData.userRoleList[0].role;
        const matchingRole = USER_ROLE.find((item) => item.value === userRole);
        setFieldValue('role', matchingRole);
      }
      if (userData?.userRoleList && userData.userRoleList.length > 0) {
        const advertiserIds = userData.userRoleList[0].advertiserIds;

        if (advertiserIds && advertiserIds.length > 0) {
          if (advertiserIds.includes(-1)) {
            setFieldValue('advertiserList', ['ALL']);
          } else {
            setFieldValue('advertiserList', advertiserIds);
          }
        }
      }

      if (userData?.role && userData?.role[0] === 'SUPERADMIN') {
        setIsSuperAdmin(true);
      }
    }
  }, [userData, setFieldValue, advertisersData]);

  const USER_ROLE_OPTIONS = useMemo(() => {
    if (roles.includes('SUPERADMIN')) {
      return [...USER_ROLE, { label: 'Super Admin', value: 'SUPERADMIN' }];
    } else {
      return USER_ROLE;
    }
  }, [roles]);

  const tncLoader = tncUpdated === 'true' && isLoadingCreateUser;

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            {!!userId ? 'Edit User' : 'Create New User'}
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} rowSpacing={0.1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                info="Enter name of user"
                disabled={isSuperAdmin || userId}
                required
                fullWidth
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
                label="Name"
                margin="normal"
                name="name"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('name', event.target.value)}
                value={values.name}
                variant="outlined"
              />
              {serverErrors.name && <p className="text-danger mt-n2">{serverErrors.name}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                info="Enter email id of user"
                disabled={userId}
                required
                fullWidth
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('email', event.target.value)}
                value={values.email}
                variant="outlined"
              />
              {serverErrors.email && <p className="text-danger mt-n2">{serverErrors.email}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                info="Enable the status to create account. Disable it when not required"
                options={USER_STATUS}
                value={values.status}
                onChange={(obj) => setFieldValue('status', obj)}
                placeholder={`Status`}
                className="py-2"
              />{' '}
              {serverErrors.status && <p className="text-danger mt-n2">{serverErrors.status}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                info="Select role of user to give access to account as per the role. e.g Admin, Viewer "
                options={USER_ROLE_OPTIONS}
                value={values.role}
                onChange={(obj) => setFieldValue('role', obj)}
                placeholder={`Role`}
                className="py-2"
              />{' '}
              {serverErrors.role && <p className="text-danger mt-n2">{serverErrors.role}</p>}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                info="Select advertisers"
                options={advertiserOptions}
                value={
                  values.advertiserList?.includes('ALL')
                    ? [{ label: 'All Advertisers', value: 'ALL' }]
                    : advertiserOptions.filter((adv) => values.advertiserList.includes(adv.value))
                }
                onChange={handleAdvertiserChange}
                placeholder="Advertiser"
                className="py-2"
                isMulti
              />
              {serverErrors.advertiserList && (
                <p className="text-danger mt-n2">{serverErrors.advertiserList}</p>
              )}
            </Grid>
            {!userId && (
              <>
                <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                  <NewTextField
                    info="Enter password "
                    type="password"
                    required
                    fullWidth
                    error={Boolean(touched.password && errors.password)}
                    helperText={touched.password && errors.password}
                    label="Password"
                    margin="normal"
                    name="password"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('password', event.target.value)}
                    value={values.password}
                    variant="outlined"
                  />{' '}
                  {serverErrors.password && (
                    <p className="text-danger mt-n2">{serverErrors.password}</p>
                  )}
                </Grid>

                <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
                  <NewTextField
                    info="Re-enter password to confirm"
                    type="password"
                    required
                    fullWidth
                    error={Boolean(touched.rePassword && errors.rePassword)}
                    helperText={touched.rePassword && errors.rePassword}
                    label="Confirm Password"
                    margin="normal"
                    name="rePassword"
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue('rePassword', event.target.value)}
                    value={values.rePassword}
                    variant="outlined"
                  />{' '}
                  {serverErrors.rePassword && (
                    <p className="text-danger mt-n2">{serverErrors.rePassword}</p>
                  )}
                </Grid>
              </>
            )}
          </Grid>
          {!isSuperAdmin && (
            <div className="mt-4 d-flex justify-content-end ">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                type="button"
                onClick={() => history.push(`/user`)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="btn btn-primary d-flex ml-4"
                type="submit"
                disabled={tncLoader || !values.name}
              >
                {!!userId ? (tncLoader ? 'Updating' : 'Update') : tncLoader ? 'Creating' : 'Create'}
              </Button>
            </div>
          )}
        </form>
      </Paper>
      {tncLoader && <OverlapLoader />}
    </div>
  );
};
