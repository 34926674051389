import React, { useState } from 'react';
import {
  Box,
  Avatar,
  ListItemIcon,
  Divider,
  Tooltip,
  Popover,
  MenuItem,
  IconButton,
  Typography,
  Select,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/ExitToApp';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useMutation, useQuery } from 'react-query';
import { useSnackbar } from 'notistack';
import queryString from 'query-string';
import getSessionData from './../../../service-utils/session-util';
import CookieUtil from './../../../service-utils/cookie-util';
import Services from '../../../service-utils/services';
import history from '../../../history';
import { CONTENT_URL } from '../../../common/constants';
import { BusinessCenter } from '@mui/icons-material';
import auth from '../../../common/auth';

export function UserMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { userFullName, agencyId, userId, agencyName } = getSessionData();
  const { removeAllCookie } = CookieUtil();
  const { enqueueSnackbar } = useSnackbar();
  const { setAgencySelectData } = auth();

  // Fetch agency logo
  const { data: logoUrl = {} } = useQuery(
    ['AGENCY_LOGO_DATA', agencyId],
    async () => (await Services.getAgencyLogo(agencyId))?.data,
    { enabled: !!agencyId },
  );

  // Fetch user agency list
  const { data: agencyList = [] } = useQuery(
    ['USER_MENU_ROLE_LIST', userId],
    async () => {
      const response = await Services.getUserRoleList(userId);
      return response.data.userDTO.userRoleList.filter((role) => role.status === 'ENABLED');
    },
    {
      enabled: !!userId,
      retry: 1,
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Failed to fetch agency data';
        //enqueueSnackbar(errorMsg, { variant: 'error' });
      },
    },
  );

  const switchAgencyMutation = useMutation(async (agency) => agency, {
    onSuccess: (agency) => {
      setAgencySelectData({ agency });
      enqueueSnackbar(`Switched to ${agency.agencyName}`, { variant: 'success' });
      history.replace('/dashboard');
    },
    onError: () => enqueueSnackbar('Failed to switch agency', { variant: 'error' }),
  });

  // Logout mutation
  const { mutate: logoutUser } = useMutation(
    async () => {
      const query = queryString.stringify({ agencyId });
      return (await Services.userLogout(query)).data;
    },
    {
      onSuccess: () => {
        enqueueSnackbar('Logout successful.', { variant: 'success' });
        removeAllCookie();
        history.replace('/');
      },
      onError: () => {
        enqueueSnackbar('Error logging out.', { variant: 'error' });
        removeAllCookie();
        history.replace('/');
      },
    },
  );

  // Event handlers
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);
  const handleAgencySwitch = (agency) => {
    switchAgencyMutation.mutate(agency);
    setMenuAnchorEl(null);
  };
  const handleLogout = () => logoutUser();

  const truncateText = (text, maxLength) =>
    text?.length > maxLength ? text.substring(0, maxLength) + '...' : text;
  const currentPath = history.location.pathname;
  // const isCustomerPage = currentPath.startsWith('/advertiser');
  const updateProfilepath = currentPath.startsWith('/update-profile');
  const updatePasswordpath = currentPath.startsWith('/update-password');

  return (
    <>
      <div>
        <Box>
          <Tooltip
            className="AvtarButton"
            title={
              <>
                {/* <Typography variant="body1" className="text-white">
                  Adzylytics Account
                </Typography> */}
                <Typography variant="body2" className="text-white">
                  Agency: {agencyName}
                </Typography>
              </>
            }
          >
            <div className="d-flex justify-content-center align-items-center">
              {logoUrl && (
                <img
                  className="pr-2"
                  src={`${CONTENT_URL}/${logoUrl}`}
                  style={{ maxWidth: '150px', maxHeight: '40px' }}
                  alt="Agency Logo"
                />
              )}
              <IconButton onClick={handleClick} size="small" sx={{ ml: 2 }}>
                <Avatar className="mainAvatar"></Avatar>
              </IconButton>
            </div>
          </Tooltip>
        </Box>

        {/* User Menu Popover */}
        <Popover
          //id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          PaperProps={{
            style: {
              borderRadius: '20px',
              marginTop: '5px', // Adjust margin to move it down
            },
          }}
        >
          <MenuItem className="mt-2">
            <Typography className="ml-2 font-weight-bold">
              Hi, {truncateText(userFullName, 15)}
            </Typography>
          </MenuItem>

          {agencyList?.length > 1 && (
            <MenuItem>
              <ListItemIcon className="ml-1">
                <BusinessCenter sx={{ color: '#dd6633' }} />
              </ListItemIcon>
              <div className="ml-4">
                <Select
                  id="advertiser-dropdown"
                  value={agencyId || ''}
                  onChange={(event) => {
                    const selectedAgency = agencyList.find(
                      (a) => a.agencyId === event.target.value,
                    );
                    if (selectedAgency) {
                      handleAgencySwitch(selectedAgency);
                    }
                  }}
                  className=""
                  displayEmpty
                  variant="standard"
                  disableUnderline
                  // sx={{ pr: 6 }} // Ensures pointer cursor
                >
                  {agencyList.map((agency) => (
                    <MenuItem key={agency.agencyId} value={agency.agencyId}>
                      {truncateText(agency.agencyName, 15)}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </MenuItem>
          )}

          <Divider />

          {!updateProfilepath && (
            <MenuItem onClick={() => history.push('/update-profile') & handleClose()}>
              <ListItemIcon>
                <Avatar
                  className="mainAvatar"
                  fontSize="small"
                  style={{ color: '#dd6633', width: 18, height: 18 }}
                ></Avatar>
              </ListItemIcon>

              <Typography className="ml-3">Update Profile</Typography>
            </MenuItem>
          )}

          {/* Change Password */}
          {!updatePasswordpath && (
            <MenuItem onClick={() => history.push('/update-password') & handleClose()}>
              <ListItemIcon>
                <LockOpenIcon fontSize="small" />
              </ListItemIcon>
              <Typography className="ml-4">Change Password</Typography>
            </MenuItem>
          )}

          {/* Logout */}
          <MenuItem onClick={handleLogout}>
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <Typography className="ml-4">Logout</Typography>
          </MenuItem>
        </Popover>
      </div>
    </>
  );
}
