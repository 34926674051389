import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Typography from '@mui/material/Typography';
import Drawer from '@mui/material/Drawer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import BookIcon from '@mui/icons-material/MenuBook';
import { Link, useTheme } from '@mui/material';
import { useLocation } from 'react-router-dom';
import SideNavigationMenu from './menuItem';
import './side-nav.scss';
import Logo from '../../../assets/img/adzy.png';
import LogoSquare from '../../../assets/img/adzy-square.png';
import history from '../../../history';

const drawerWidth = 260;

const StyledDrawer = styled(Drawer)(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: 'var(--sidebar-bg)',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: 0.1, // Fast close
    }),
    ...(open
      ? { width: drawerWidth }
      : {
          overflowX: 'hidden',
          width: 'inherit',
        }),
  },
}));

const StyledToolbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center', // Center logo
  padding: theme.spacing(0, 1),
  // height: '80px', // Adjust for proper centering
  ...theme.mixins.toolbar,
}));

const LogoContainer = styled('button')({
  border: 'none',
  background: 'none',
  padding: 0,
  cursor: 'pointer',
});

const SideNavigation = ({ open, handleDrawerClose, handleDrawerOpen }) => {
  const theme = useTheme();
  const location = useLocation();
  const [isSideNavOpen, setSideNavigation] = useState(open);
  const [hover, setHover] = useState(false);

  const toggleHover = () => {
    if (!isSideNavOpen) {
      setSideNavigation(false);
      handleDrawerOpen('hover');
    }
    setHover(true);
  };

  const goToLandingPage = () => {
    history.push('/dashboard');
  };

  // const closeSideNav = () => {
  //   setSideNavigation(false);
  //   handleDrawerClose();
  //   localStorage.setItem('sideNav', false);
  // };

  const onHoverClose = () => {
    if (!isSideNavOpen) {
      setSideNavigation(false);
      handleDrawerClose();
    }
    setHover(false);
  };

  const isAdvertiserPage = location.pathname.startsWith('/advertiser');

  return (
    <div className="tenant-admin-nav">
      <StyledDrawer
        variant="permanent"
        open={open}
        onMouseEnter={toggleHover}
        onMouseLeave={onHoverClose}
      >
        <StyledToolbar>
          {!isSideNavOpen && hover ? (
            <LogoContainer onClick={goToLandingPage} aria-label="Go to landing page">
              <img
                src="https://content.adzylytics.com/static/images/adzylytics_logo.png"
                alt="Logo"
                className="logo"
              />
            </LogoContainer>
          ) : (
            <img
              src="https://content.adzylytics.com/static/images/icon_36x36.png"
              alt=""
              className="logo-sqaure mr-n2"
            />
          )}
        </StyledToolbar>

        <div className="d-flex menu-sidebar">
          <SideNavigationMenu open={open} />
          {isAdvertiserPage && (
            <MenuItem onClick={() => history.push('/dashboard')}>
              <ListItemIcon>
                <ArrowBackIcon fontSize="small" />
              </ListItemIcon>
              <Typography style={{ color: 'white' }} className="ml-3">
                Home
              </Typography>
            </MenuItem>
          )}
        </div>

        <div className="mb-2">
          <MenuItem>
            <ListItemIcon>
              <BookIcon fontSize="small" style={{ height: '22px' }} />
            </ListItemIcon>
            <Link href="https://docs.adzylytics.com/" target="_blank" rel="noopener">
              <Typography style={{ color: 'white' }} className="ml-3">
                Documentation
              </Typography>
            </Link>
          </MenuItem>
        </div>
      </StyledDrawer>
    </div>
  );
};

export default SideNavigation;
