import React, { useEffect, useState } from 'react';
import { LoginForm } from '../../components';
import { Grid } from '../../common';
import history from '../../history';
import auth from '../../common/auth';
import './login.scss';
import Services from '../../service-utils/services';
import { useMutation } from 'react-query';
import { useSnackbar } from 'notistack';
import OverlapLoader from '../../common/loader/OverlapLoader';
export const Login = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [errorText, setErrorText] = useState();
  const { setTokenData, setUserData, setLoginUserData } = auth();

  useEffect(() => {
    setUserData('');
  }, [setUserData]);

  const { mutate: handleOnSubmit, isLoading: isLoginLoading } = useMutation(
    async (values) => {
      const { email, password } = values;
      const payload = { password, username: email };
      const { data } = await Services.userLogin(payload);
      return data;
    },

    {
      onError: (err) => {
        const errorMsg = err?.response?.data?.reasonDesc || 'Error in user login.';
        // enqueueSnackbar(errorMsg, { variant: 'error' });
        setErrorText(errorMsg);
      },
      onSuccess: (data) => {
        enqueueSnackbar('Login successful.', { variant: 'success' });
        setTokenData({
          access_token: data.token,
          refresh_token: data.refreshToken,
          agencyCurrency: data?.userDetails?.agencyCurrency,
          agencyId: data?.userDetails?.agencyId,
          roles: data?.userDetails?.roles,
          userFullName: data?.userDetails?.userFullName,
          userId: data?.userDetails?.id || data.id,
          tncUpdated: data?.tncUpdated,
        });

        setUserData(data?.userDetails?.username);
        const userAccessList = data?.userDetails?.userAccessList || [];
        if (userAccessList.length > 1) {
          history.replace('/agency-login');
        } else if (userAccessList.length === 1) {
          setLoginUserData({ userAccessList }); // Pass as an object
          history.replace('/dashboard');
        } else {
          setErrorText('No agency access found.');
        }
      },
    },
  );

  return (
    <div className="login-page">
      <Grid container className="full-height-grid">
        <Grid
          item
          size={{ xs: 12, md: 7 }}
          className="d-flex justify-content-center align-items-center slider-container"
        >
          <img
            src="https://content.adzylytics.com/static/images/adzylytics_logo.png"
            alt="logo"
            className="img-fluid"
          />
        </Grid>
        <Grid
          item
          size={{ xs: 12, md: 5 }}
          className="d-flex justify-content-center align-items-center h-100 login-form-container p-4"
        >
          <div className="text-center w-100">
            <LoginForm handleOnSubmit={handleOnSubmit} errorText={errorText} />
          </div>
        </Grid>
      </Grid>

      {isLoginLoading && <OverlapLoader />}
    </div>
  );
};

export default Login;
