import React, { useEffect, useMemo, useState } from 'react';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import { SearchInput, Table } from '../../components';
import { Button, Loader, NewSelect } from '../../common';
// import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import Services from '../../service-utils/services';
import { useQuery, useMutation } from 'react-query';
import queryString from 'query-string';
// import { Dropdown } from 'react-bootstrap';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useSnackbar } from 'notistack';
import getSessionData from '../../service-utils/session-util';
import Chip from '@mui/material/Chip';
import { ConformationBox } from '../../components/conformationBox';
import { getErrorMsg } from '../../common/utils';
// import { UserMenu } from '../../hoc/layout/side-navbar/UserMenu';
import CreateIcon from '../../components/create-icon/createIcon';
import { handleMsgOnForm } from '../../common/utils';

import {
  DeleteButton,
  DisableButton,
  EditButton,
  EnableButton,
  ReSendButton,
  SearchButton,
} from '../../components/react-icons/ReactIcons';
import { dateFormatMoment } from '../../components/format-date-moment';
import OverlapLoader from '../../common/loader/OverlapLoader';
import { USER_STATUS_OPTIONS } from '../../libs/constants';
import useDebounce from '../../components/debounce-hook';

export const Users = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [pageNumber, setPageNumber] = useState(1);
  const [pageLimit, setPageLimit] = useState(10);
  const { agencyId, user } = getSessionData();
  const [disabledId, setDisabledId] = useState();
  const [enableId, setEnableId] = useState();
  const [serverErrors, setServerErrors] = useState({});
  const { tncUpdated } = getSessionData();
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState('');
  const debouncedValue = useDebounce(searchText, 500);
  //console.log('user',user)
  const { data, isLoading, refetch } = useQuery(['USER_DATA', pageNumber, pageLimit], async () => {
    const queryParams = {
      status: status.value || '',
      pageNum: pageNumber - 1,
      pageSize: pageLimit,
      agencyId,
    };
    if (debouncedValue) {
      queryParams.name = debouncedValue;
    }

    const response = await Services.getAgencyUsers(queryString.stringify(queryParams));
    return response.data;
  });

  useEffect(() => {
    dispatch(
      setHeader({
        header: (
          <div className="d-flex align-items-center justify-content-between position-relative">
            Users
          </div>
        ),
      }),
    );
  }, [dispatch]);

  const handleTableChange = async (type, { page, sizePerPage }) => {
    setPageLimit(sizePerPage);
    setPageNumber(page);
  };

  const handleAddUser = () => {
    history.push(`/user-create`);
  };

  const handleEditUser = (userId) => {
    if (userId) {
      history.push(`/user/${userId}`);
    }
  };

  // const { mutate: handleDeleteUser } = useMutation(
  //   async (userId) => {
  //     const query = queryString.stringify({ agencyId });
  //     const response = await Services.userDelete(userId, query);
  //     return response.data;
  //   },
  //   {
  //     onError: (err) => {
  //       enqueueSnackbar(getErrorMsg(err, 'Error in deleting a user.'), { variant: 'error' });
  //       setDeleteId();
  //     },
  //     onSuccess: () => {
  //       enqueueSnackbar('User deleted successfully.', { variant: 'success' });
  //       setDeleteId();
  //       refetch();
  //     },
  //   },
  // );

  const { mutate: handleStatusChange } = useMutation(
    async (payload) => {
      const { userId, status } = payload;
      const response = await Services.userStatusUpdate(userId, status, agencyId);
      return response.data;
    },
    {
      onError: (err, data) => {
        //console.log('err',err,data)
        enqueueSnackbar('Error in updating status.', { variant: 'error' });
      },
      onSuccess: (a, b) => {
        //console.log(a,b)
        refetch();
        setDisabledId();
        setEnableId();
        enqueueSnackbar('Updated status successfully.', { variant: 'success' });
      },
    },
  );

  const tableData = useMemo(() => {
    return data?.users || [];
  }, [data]);

  const handleIcons = (row) => {
    const { id, status } = row;
    return (
      <div className="flex items-center gap-2">
        {status === 'INVITED' ? (
          <>
            <ReSendButton handleApply={() => handleSendInvitation(row)} />
          </>
        ) : (
          <>
            <EditButton disabled={status === 'INVITED'} handleEdit={() => handleEditUser(id)} />
            {status === 'ENABLED' ? (
              <DisableButton
                disabled={status === 'INVITED'}
                handleToggle={() => setDisabledId(id)}
              />
            ) : (
              <EnableButton disabled={status === 'INVITED'} handleToggle={() => setEnableId(id)} />
            )}
          </>
        )}
      </div>
    );
  };

  const { mutate: handleSendInvitation, isLoading: isLoadingCreateUser } = useMutation(
    async (values) => {
      const { id } = values;
      const query = queryString.stringify({ agencyId });
      const { data } = await Services.userResenfInvite(id, query);
      return data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar, setServerErrors);
      },
      onSuccess: (data) => {
        enqueueSnackbar('Invite Sent Successfully.', { variant: 'success' });
        history.push(`/user`);
      },
    },
  );

  // const manageButton = (row) => {
  //   return (
  //     <>
  //       <Dropdown id="dropdown-item-button" className="action-dropdown btn-action">
  //         <Dropdown.Toggle variant="link">
  //           <MoreHorizIcon />
  //         </Dropdown.Toggle>
  //         <Dropdown.Menu>
  //           <Dropdown.Item onClick={() => handleEditUser(row.id)}>Edit</Dropdown.Item>
  //           {!row.enabled && (
  //             <Dropdown.Item onClick={() => setEnableId(row.id)}>Enable User</Dropdown.Item>
  //           )}
  //           {row.enabled && (
  //             <Dropdown.Item onClick={() => setDisabledId(row.id)}>Disable User</Dropdown.Item>
  //           )}
  //           <Dropdown.Item onClick={() => setDeleteId(row.id)}>Delete</Dropdown.Item>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     </>
  //   );
  // };

  const getTableHeader = [
    {
      dataField: 'name',
      text: 'Name',
      formatter: (col, row) => <a href={`/user/${row.id}`}>{col}</a>,
    },
    {
      dataField: 'email',
      text: 'Email',
    },
    {
      dataField: 'role',
      text: 'Roles',
      formatter: (col, row) => {
        const roles = row.userRoleList?.map((role) => role.role).join(', ') || 'N/A';
        return roles;
      },
    },
    {
      dataField: 'createdDate',
      text: 'Created Date',
      formatter: (col, row) => dateFormatMoment(col, 'DD MMM YY hh:mm A'),
    },
    {
      dataField: 'enabled',
      text: 'Status',
      formatter: (col, row) => {
        const status = row && row?.status ? row?.status : '';
        switch (status) {
          case 'ENABLED':
            return <Chip label="Enabled" className="alert-success" />;
          case 'DISABLED':
            return <Chip label="Disabled" className="alert-danger" />;
          case 'INVITED':
            return <Chip label="Invited" className="alert-warning" />;
          default:
            return <Chip label={status} className="alert-warning" />;
        }
      },
    },
    // {
    //   dataField: 'id',
    //   text: '',
    //   formatter: (col, row) => (row.email !== user ? manageButton(row) : <></>),
    // },
    {
      dataField: 'id',
      text: '',
      formatter: (col, row) => (row.email !== user ? handleIcons(row) : <></>),
    },
  ];
  const tncLoader = tncUpdated === 'true' && isLoadingCreateUser;

  return (
    <>
      <div className="mb-3 d-flex justify-content-between filters">
        <div className="d-flex">
          <SearchInput
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
            value={searchText}
          />
          <NewSelect
            required
            options={USER_STATUS_OPTIONS}
            value={status}
            onChange={(obj) => setStatus(obj)}
            placeholder={`Status`}
            isMulti={false}
            // info="hello"
            className="mt-n3 ml-3"
          />
          <Button
            variant="contained"
            className="btn btn-primary d-flex btn-38 ml-3 btn-add"
            startIcon={<SearchButton />}
            onClick={() => refetch()}
          >
            Apply
          </Button>
        </div>
        <div>
          <CreateIcon label="Create New User" handleAdd={handleAddUser} />
        </div>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <Table
          keyField="id"
          tableData={tableData}
          tableHeader={getTableHeader}
          isRemote={true}
          onPageChangeFun={handleTableChange}
          totalSize={data?.totalElements}
          activePage={pageNumber}
          sizePerPage={pageLimit}
          wrapperClasses="scroll-visible"
          defaultSorted={[
            {
              dataField: 'name',
              order: 'asc',
            },
          ]}
        />
      )}
      <ConformationBox
        isOpen={!!disabledId}
        handleClose={() => setDisabledId()}
        handleSubmit={() => handleStatusChange({ userId: disabledId, status: 'DISABLED' })}
        title="Disable ?"
        subtitle="Are you sure you want to disable this user?"
        btnCloseLabel="Close"
        btnSubmitLabel="Disable"
      />
      <ConformationBox
        isOpen={!!enableId}
        handleClose={() => setEnableId()}
        handleSubmit={() => handleStatusChange({ userId: enableId, status: 'ENABLED' })}
        title="Enable ?"
        subtitle="Are you sure you want to enable this user?"
        btnCloseLabel="Close"
        btnSubmitLabel="Enable"
      />
      {/* <ConformationBox
        isOpen={!!deleteId}
        handleClose={() => setDeleteId()}
        handleSubmit={() => handleDeleteUser(deleteId)}
        title="Delete ?"
        subtitle="Are you sure you want to delete this user?"
        btnCloseLabel="Close"
        btnSubmitLabel="Delete"
      /> */}
      {tncLoader && <OverlapLoader />}
    </>
  );
};
