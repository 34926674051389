import React, { useState, useRef, useEffect } from 'react';
import Header from './header';
import Footer from './footer';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserMenu } from '../../hoc/layout/side-navbar/UserMenu';
import { MenuItem, Select } from '@mui/material';
import queryString from 'query-string';
import getSessionData from './../../service-utils/session-util';
import { useQuery } from 'react-query';
import Services from '../../service-utils/services';
import { useAdvertiser } from '../../context/AdvertiserProvider';
import TermsAndCondition from '../../components/terms-and-condition/termsAndCondition';
const Layout = ({ headers, children, history }) => {
  const { updateAdvertiserId } = useAdvertiser();
  let { agencyId, advertiserId, tncUpdated } = getSessionData();
  const [advertiserOptions, setAdvertiserOptions] = useState([]);
  const { header, caption, back, child } = headers;
  const [selectedAdvertiser, setSelectedAdvertiser] = useState(null);
  const containerRef = useRef();
  const showHeader = header || caption || back;
  const location = useLocation();
  const { userId } = getSessionData();
  const isAdvertiserRoute = location.pathname.startsWith('/advertiser');
  const [isOpen, checkIsOpen] = useState(localStorage.getItem('sideNav') === 'true');
  const isOpenSidebar = (open) => {
    checkIsOpen(open);
  };
  const { data: allAdvertiser = [], isLoading: isLoadingAllAdv } = useQuery(
    ['ALL_ADVERTISERS', agencyId],
    async () => {
      const queryStringParams = {
        pageSize: 5000,
        pageNum: 0,
        agencyId,
      };
      const response = await Services.advertisersGetAll(queryString.stringify(queryStringParams));
      const advertisers = response.data?.advertiserList || [];
      return advertisers;
    },
    { enabled: !!agencyId },
  );

  useEffect(() => {
    if (!isLoadingAllAdv && allAdvertiser) {
      const activeAdvertiser = allAdvertiser.map((item) => ({
        label: item.name,
        value: item.id,
      }));
      setAdvertiserOptions(activeAdvertiser);
    }
  }, [allAdvertiser, isLoadingAllAdv]);
  const handleChange = (event) => {
    const selectedOption = allAdvertiser.find((option) => option.id === event.target.value);
    setSelectedAdvertiser(selectedOption);
    updateAdvertiserId(selectedOption?.id);
    const isLineItemPage = location.pathname.startsWith('/advertiser/line-item');
    const hasQueryParams = location.search !== '';
    if (isLineItemPage && hasQueryParams) {
      history.replace('/advertiser/campaign');
      return;
    }
    const isSubPage =
      location.pathname.startsWith('/advertiser/') &&
      [
        '/advertiser/campaign',
        '/advertiser/creative',
        '/advertiser/lead-form',
        '/advertiser/inventory',
        '/advertiser/report',
        '/advertiser/pincode-segment',
        '/advertiser/ip-segment',
        '/advertiser/deviceip-segment',
      ].some((subPage) => location.pathname.startsWith(subPage));

    // If the dropdown change occurs in a sub-page (excluding line-item), navigate back to the root sub-page
    if (isSubPage && !isLineItemPage) {
      const rootSubPage = [
        '/advertiser/campaign',
        '/advertiser/creative',
        '/advertiser/lead-form',
        '/advertiser/inventory',
        '/advertiser/report',
        '/advertiser/pincode-segment',
        '/advertiser/ip-segment',
        '/advertiser/deviceip-segment',
      ].find((subPage) => location.pathname.startsWith(subPage));

      if (rootSubPage) {
        history.replace(rootSubPage);
      }
    }
  };

  const fullWidthHeader = 'mw-100';

  const className = classnames('container-fluid layout-container p-0', {
    'container-shrink': isOpen,
  });

  const headerClasses = classnames('container-fluid  header-fluid p-0');

  const handleBack = () => {
    if (back.callBack) {
      back.callBack();
    } else {
      back.link ? history.push(back.link) : history.goBack();
    }
  };

  return (
    <div className="d-flex ">
      <Header isOpenSidebar={isOpenSidebar} />
      <div
        className={className}
        style={{ transition: 'all 0.3s 0.3 ease-in-out', position: 'relative' }}
        ref={containerRef}
      >
        {showHeader && (
          <div className={headerClasses} style={{ position: 'sticky', top: 0, zIndex: 100 }}>
            <div className={`header-container d-flex flex-column ${fullWidthHeader}`}>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center">
                  {back && (
                    <button
                      className={classnames('btn btn-md btn-link p-0 d-flex align-items-center', {
                        disabled: !!back.disable,
                      })}
                      onClick={() => handleBack()}
                    >
                      <ArrowBackIcon />
                      {back.text}
                    </button>
                  )}
                  {(header || caption) && (
                    <div className={child.length > 0 ? '' : 'w-100'}>
                      {header && <h1>{header}</h1>}
                      {caption && <span>{caption}</span>}
                    </div>
                  )}
                </div>

                <div className="d-flex align-items-center">
                  {isAdvertiserRoute && !isLoadingAllAdv && (
                    <div className="mr-5">
                      <Select
                        id="advertiser-dropdown"
                        value={advertiserId || ''}
                        onChange={handleChange}
                        className=""
                        displayEmpty
                        variant="standard"
                        disableUnderline
                      >
                        {allAdvertiser.map((option) => (
                          <MenuItem key={option.id} value={option.id}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}

                  <UserMenu />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="container-fluid px-0 ">
          <div className="content-container d-flex flex-column">{children}</div>
        </div>
        {tncUpdated === 'false' ? <TermsAndCondition /> : <Footer />}
      </div>
    </div>
  );
};
const mapStateToprops = ({ headers }) => ({
  headers,
});

export default connect(mapStateToprops, null)(withRouter(Layout));
