import React, { useEffect, useState, useMemo } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { NewTextField, Paper, Grid, NewSelect, Button, Typography } from '../../common';
import { setHeader } from '../../libs/redux-sdk/actions';
import { useDispatch } from 'react-redux';
import history from '../../history';
import Services from '../../service-utils/services';
import { useMutation, useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { USER_ROLE } from '../../libs/constants';
import queryString from 'query-string';
import getSessionData from '../../service-utils/session-util';
import { handleMsgOnForm } from '../../common/utils';
import OverlapLoader from '../../common/loader/OverlapLoader';

export const UserInvite = () => {
  const { userId } = useParams();
  const dispatch = useDispatch();
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const { agencyId, roles, tncUpdated } = getSessionData();
  const [serverErrors, setServerErrors] = useState({});

  const { enqueueSnackbar } = useSnackbar();

  // Fetch all advertisers
  const { data: allAdvertiser = [], isLoading: isLoadingAllAdv } = useQuery(
    ['ALL_ADVERTISERS', agencyId],
    async () => {
      const queryStringParams = { pageSize: 5000, pageNum: 0, agencyId };
      const response = await Services.advertisersGetAll(queryString.stringify(queryStringParams));
      return response.data?.advertiserList || [];
    },
    { enabled: !!agencyId },
  );

  useEffect(() => {
    dispatch(setHeader({ back: { text: 'Back' } }));
  }, [dispatch]);

  // Mutation for creating a user invite
  const {
    mutate: createUserInvite,
    isLoading: isLoadingCreateUser,
    data: inviteResponse,
  } = useMutation(
    async (values) => {
      const { email, roleList, advertiserList } = values;
      const payload = {
        email,
        role: roleList?.value,
        agencyId: parseInt(agencyId),
        // If 'All' is selected, pass an empty list
        advertiserIds: advertiserList.includes('ALL') ? [] : advertiserList,
      };

      const query = queryString.stringify({ agencyId });
      const { data } = await Services.userInviteCreate(payload, query);
      return data;
    },
    {
      onError: (err) => handleMsgOnForm(err, enqueueSnackbar, setServerErrors),
      onSuccess: () => {
        enqueueSnackbar('Invite Sent Successfully.', { variant: 'success' });
        history.push(`/user`);
      },
    },
  );

  // Prepare advertiser options with 'All' option
  const advertiserOptions = useMemo(() => {
    const advOptions = allAdvertiser.map((adv) => ({
      label: adv.name,
      value: adv.id,
    }));

    // Add 'All' option at the beginning
    return [{ label: 'All Advertisers', value: 'ALL' }, ...advOptions];
  }, [allAdvertiser]);

  // Formik for form handling
  const { handleSubmit, values, touched, errors, handleBlur, setFieldValue } = useFormik({
    initialValues: {
      email: '',
      roleList: USER_ROLE[2],
      advertiserList: [], // Ensuring advertiser list is an array
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Invalid email address').required('Email is required.'),
    }),
    onSubmit: () => createUserInvite(values),
  });

  // Update advertiser list when invite response is received
  useEffect(() => {
    if (inviteResponse?.userDTO?.advertiserIds) {
      const responseAdvertiserIds = inviteResponse.userDTO.advertiserIds;

      // Check if all advertisers are selected
      if (responseAdvertiserIds.length === allAdvertiser.length) {
        setFieldValue('advertiserList', ['ALL']);
      } else {
        setFieldValue('advertiserList', responseAdvertiserIds);
      }
    }
  }, [inviteResponse, allAdvertiser, setFieldValue]);

  // Handle advertiser selection with 'All' logic
  const handleAdvertiserChange = (selectedOptions) => {
    // Normalize selectedOptions to an array
    const options = Array.isArray(selectedOptions)
      ? selectedOptions
      : selectedOptions
      ? [selectedOptions]
      : [];

    // If 'All' is selected, deselect other options
    const hasAll = options.some((opt) => opt.value === 'ALL');

    if (hasAll) {
      setFieldValue('advertiserList', ['ALL']);
    } else {
      // Remove 'ALL' if other options are selected
      const filteredOptions = options.filter((opt) => opt.value !== 'ALL');
      setFieldValue(
        'advertiserList',
        filteredOptions.map((opt) => opt.value),
      );
    }
  };

  // Memoized user role options
  const USER_ROLE_OPTIONS = useMemo(() => {
    if (roles.includes('SUPERADMIN')) {
      return [...USER_ROLE, { label: 'Super Admin', value: 'SUPERADMIN' }];
    }
    return USER_ROLE;
  }, [roles]);

  const tncLoader = tncUpdated === 'true' && isLoadingCreateUser;

  return (
    <div className="col-md-10 bg-white rounded shadow m-auto mb-4 p-0">
      <Paper className="pt-3 p-4 mn-h-200">
        <div className="d-flex justify-content-center mb-3">
          <Typography color="textPrimary" variant="h5">
            Create New User
          </Typography>
        </div>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} rowSpacing={0.1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewTextField
                info="Enter email ID of user"
                disabled={!!userId}
                required
                fullWidth
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
                label="Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={(event) => setFieldValue('email', event.target.value)}
                value={values.email}
                variant="outlined"
              />
              {serverErrors.email && <p className="text-danger mt-n2">{serverErrors.email}</p>}
            </Grid>

            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                info="Select the role of the user"
                options={USER_ROLE_OPTIONS}
                value={values.roleList}
                onChange={(obj) => setFieldValue('roleList', obj)}
                placeholder="Role"
                className="py-2"
              />
              {serverErrors.roleList && (
                <p className="text-danger mt-n2">{serverErrors.roleList}</p>
              )}
            </Grid>
            <Grid item size={{ xs: 12, sm: 6 }} className="py-0">
              <NewSelect
                info="Select advertisers"
                options={advertiserOptions}
                value={
                  values.advertiserList.includes('ALL')
                    ? [{ label: 'All Advertisers', value: 'ALL' }]
                    : advertiserOptions.filter((adv) => values.advertiserList.includes(adv.value))
                }
                onChange={handleAdvertiserChange}
                placeholder="Advertiser"
                className="py-2"
                isMulti
              />
              {serverErrors.advertiserList && (
                <p className="text-danger mt-n2">{serverErrors.advertiserList}</p>
              )}
            </Grid>
          </Grid>

          {!isSuperAdmin && (
            <div className="mt-4 d-flex justify-content-end">
              <Button
                variant="contained"
                className="btn btn-secondary d-flex"
                type="button"
                onClick={() => history.push(`/user`)}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                className="btn btn-primary d-flex ml-4"
                type="submit"
                disabled={tncLoader}
              >
                {!!userId ? (tncLoader ? 'Updating' : 'Update') : tncLoader ? 'Creating' : 'Create'}
              </Button>
            </div>
          )}
        </form>
      </Paper>
      {tncLoader && <OverlapLoader />}
    </div>
  );
};
