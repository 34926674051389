import React, { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Paper,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  InputAdornment,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useMutation } from 'react-query';
import Services from '../../service-utils/services';
import { useSnackbar } from 'notistack';
import { handleMsgOnForm } from '../../common/utils';
import queryString from 'query-string';
import { CONTENT_URL } from '../../common/constants';
import Tooltip from '@mui/material/Tooltip';
import { getSessionData } from '../../service-utils';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import './lead-form.scss';
import { NewTextField, Grid } from '../../common';
import SaveIcon from '@mui/icons-material/Save';
import { ConformationBox } from '../../components/conformationBox';
import { useFormik } from 'formik';
import OverlapLoader from '../../common/loader/OverlapLoader';
export const DyanamicForm = (props) => {
  const { goToNextStep, leadFormData, refreshPage } = props;
  const [formFields, setFormFields] = useState([]);
  const [newField, setNewField] = useState({
    label: '',
    type: 'text',
    options: [],
  });
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [SelectedFieldIndex, setSelectedFieldIndex] = useState(null);
  const [editedOption, setEditedOption] = useState('');
  const [SelectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [landingFormData, setLandingFormData] = useState(null);
  const [isDropdownField, setIsDropdownField] = useState(false);
  const [showAddFieldPopup, setShowAddFieldPopup] = useState(false);
  const [showAddDropdownOptionsPopup, setShowAddDropdownOptionsPopup] = useState(false);
  const [submitButtonName, setSubmitButtonName] = useState('Submit'); // Initial name
  const [editedLabel, setEditedLabel] = useState('');
  const [editedType, setEditedType] = useState('');
  const [editedFieldIndex, setEditedFieldIndex] = useState(null);
  const [editingLabel, setEditingLabel] = useState(false);
  const [isEditingButton, setIsEditingButton] = useState(false);
  const [content, setContent] = useState('Welcome! Feel free to reach out to us');
  const [isEditing, setIsEditing] = useState(false);
  const [contentTitle, setContentTitle] = useState('Contact Us');
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [thankuouMsg, setThankuouMsg] = useState('Thank you for Submitting');
  // const [isEditingThankuouMsg, setIsEditingThankuouMsg] = useState(false);
  const maxVisibleCharsForTitle = 255;
  const maxVisibleChars = 255;
  const { enqueueSnackbar } = useSnackbar();
  // const { formId } = useParams();
  const { agencyId, advertiserId } = getSessionData();
  const [formFieldData, setFormFieldsData] = useState();
  const [conformation, setConformation] = useState();

  useEffect(() => {
    if (leadFormData) {
      setFormFieldsData(leadFormData?.formFieldsList || []);
      setLandingFormData(leadFormData);
    }
  }, [leadFormData, refreshPage]);

  useEffect(() => {
    if (leadFormData?.id) {
      refreshPage();
    }
  }, [leadFormData]);

  useEffect(() => {
    if (landingFormData !== null) {
      setContent(landingFormData?.description || 'Welcome! Feel free to reach out to us');
      setContentTitle(landingFormData?.title || 'Contact Us');
      setSubmitButtonName(landingFormData?.buttonName || 'Submit');
      setThankuouMsg(landingFormData?.thankYouNote || 'Thank you for Submitting');
    }
  }, [landingFormData]);

  const uploadMediaMutation = useMutation(async ({ file, imgType, refreshPage }) => {
    try {
      let formData = new FormData();
      formData.append('file', file);
      let formId = landingFormData.id;
      const response = await Services.uploadImage(
        advertiserId,
        formId,
        agencyId,
        imgType,
        formData,
      );

      enqueueSnackbar(`${imgType} Upload successfully.`, {
        variant: 'success',
      });
      refreshPage();
    } catch (error) {
      console.error('Error during API call:', error);
      enqueueSnackbar(`Error uploading ${imgType}.`, {
        variant: 'error',
      });
    }
  });

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    // setProfileImage(file);
    const imgType = 'BANNER'; // Set imgType here
    console.log('handleImageChange - imgType:', imgType);
    uploadMediaMutation.mutate({ file, imgType, refreshPage }); // Pass an object with file and imgType
  };

  const handleLogoChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const image = new Image();
      image.src = URL.createObjectURL(file);

      image.onload = () => {
        const maxWidth = 300;
        const maxHeight = 150;

        if (image.width <= maxWidth && image.height <= maxHeight) {
          const imgType = 'LOGO'; // Set imgType here
          console.log('Valid dimensions. Uploading...', imgType);

          // Assuming uploadMediaMutation takes an object with file and imgType
          uploadMediaMutation.mutate({ file, imgType, refreshPage });
        } else {
          enqueueSnackbar(`Maximum dimensions allowed: 300px x 150px or smaller`, {
            variant: 'error',
          });
          e.target.value = null;
        }
      };
    }
  };

  useEffect(() => {
    if (formFieldData) {
      setFormFields(formFieldData);
    }
  }, [formFieldData]);

  const SelectedFieldsData = formFields.map((field) => {
    const fieldData = {
      label: field.label,
      type: field.type,
    };

    if (field.type === 'dropdown') {
      fieldData.options = field.options;
    }

    return fieldData;
  });

  const { mutate: buildForm, isLoading: isLoadingCreateleadForm } = useMutation(
    async () => {
      let name = landingFormData?.name;
      let formId = landingFormData.id;

      const payload = {
        name: name,
        formFieldsList: SelectedFieldsData,
        description: content,
        title: contentTitle,
        buttonName: submitButtonName,
        // published: publish,
        thankYouNote: thankuouMsg,
      };
      const query = queryString.stringify({ agencyId });
      const response = await Services.leadFormUpdate(advertiserId, formId, query, payload);

      return response.data;
    },
    {
      onError: (err) => {
        handleMsgOnForm(err, enqueueSnackbar);
      },
      onSuccess: (data) => {
        enqueueSnackbar('Successfully Submit.', { variant: 'success' });
        goToNextStep(data?.leadFormDTO?.id);
      },
    },
  );

  const { handleSubmit, values } = useFormik({
    initialValues: {
      name: '',
    },

    onSubmit: () => buildForm(values),
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (value === 'text' || value === 'email' || value === 'number') {
      setNewField((prevField) => ({
        ...prevField,
        [name]: value,
        options: [],
      }));
    } else {
      setNewField((prevField) => ({
        ...prevField,
        [name]: value,
      }));
    }
  };

  const handleEditDialogOpen = (fieldIndex, optionIndex) => {
    setSelectedFieldIndex(fieldIndex);
    setSelectedOptionIndex(optionIndex);
    const fieldType = formFields[fieldIndex]?.type;
    setEditedOption(formFields[fieldIndex]?.options[optionIndex]);

    // Set the appropriate dialog open state based on field type
    if (fieldType === 'dropdown') {
      setIsDropdownField(true);
    } else if (['text', 'email', 'number'].includes(fieldType)) {
      // setEditFieldDialogOpen(true);
      setIsDropdownField(false);
    }
  };

  const handleEditDialogClose = () => {
    setSelectedFieldIndex(null);
    setEditDialogOpen(false);
  };

  // const addField = () => {
  //   if (newField.label.trim() !== '') {
  //     setFormFields((prevFields) => [...prevFields, { ...newField }]);
  //     setNewField((prevField) => ({
  //       label: '',
  //       type: 'text',
  //       options: [...prevField.options],
  //     }));
  //   }
  // };
  const handleEditOption = () => {
    if (SelectedFieldIndex !== null && SelectedOptionIndex !== null) {
      const updatedFormFields = [...formFields];
      updatedFormFields[SelectedFieldIndex].options[SelectedOptionIndex] = editedOption;
      setFormFields(updatedFormFields);

      handleEditDialogClose();

      setSelectedFieldIndex(null);
      setSelectedOptionIndex(null);
      setEditedOption('');
    }
  };

  const handleFieldEditOpen = (index) => {
    setSelectedFieldIndex(index);
    setEditedFieldIndex(index);
    setEditingLabel(true); // You can choose to edit the label by default
    setEditedLabel(formFields[index].label);
    setEditedType(formFields[index].type);
    const fieldType = formFields[index]?.type;

    if (fieldType === 'dropdown') {
      setIsDropdownField(true);
      setEditedOption(formFields[index]?.options[0]); // Assuming you want the first option
      setEditDialogOpen(true);
    } else if (['text', 'email', 'number'].includes(fieldType)) {
      setIsDropdownField(false);
      setEditDialogOpen(true);
    }
  };

  const handleAddNewOption = () => {
    if (SelectedFieldIndex !== null) {
      const updatedFormFields = [...formFields];
      updatedFormFields[SelectedFieldIndex].options.push(editedOption);
      setFormFields(updatedFormFields);

      setEditedOption('');
    }
  };

  const handleDeleteOption = (fieldIndex, optionIndex) => {
    setFormFields((prevFormFields) => {
      const updatedFields = [...prevFormFields];
      const fieldToUpdate = { ...updatedFields[fieldIndex] };
      fieldToUpdate.options.splice(optionIndex, 1);
      updatedFields[fieldIndex] = fieldToUpdate;
      return updatedFields;
    });
  };

  const handleDeleteFields = (fieldIndex) => {
    setFormFields((prevFormFields) => {
      const updatedFields = [...prevFormFields];
      updatedFields.splice(fieldIndex, 1);
      return updatedFields;
    });
  };

  const handleSpecialFieldEditChange = (e) => {
    const { name, value } = e.target;
    if (name === 'editedLabel') {
      setEditedLabel(value);
    } else if (name === 'editedType') {
      setEditedType(value);
    }
  };
  const handleSpecialFieldEditSave = () => {
    const updatedFormFields = [...formFields];
    updatedFormFields[editedFieldIndex].label = editedLabel; // Update label
    updatedFormFields[editedFieldIndex].type = editedType; // Update type
    setFormFields(updatedFormFields);
    handleEditDialogClose();
  };

  useEffect(() => {
    if (formFieldData && formFieldData.length > 0) {
      // If formFieldData is not empty, set formFields to formFieldData
      setFormFields(formFieldData);
    } else {
      // If formFieldData is empty, set default fields
      setFormFields([
        { label: 'Name', type: 'text' },
        { label: 'Email', type: 'email' },
      ]);
    }
  }, [formFieldData]);
  const handleAddFieldPopupOpen = () => {
    setShowAddFieldPopup(true);
  };

  const handleAddFieldPopupClose = () => {
    setShowAddFieldPopup(false);
  };

  const handleAddFieldSave = () => {
    if (newField.label.trim() !== '') {
      setFormFields((prevFields) => [...prevFields, { ...newField }]);
      setNewField((prevField) => ({
        label: '',
        type: 'text',
        options: [...prevField.options],
      }));
      handleAddFieldPopupClose();
    }
  };

  const handleAddOption = () => {
    if (newField.option.trim() !== '') {
      setNewField((prevField) => ({
        ...prevField,
        options: [...prevField.options, newField.option],
        option: '',
      }));
    }
  };

  // const handleAddDropdownOptionsPopupClose = () => {
  //   setShowAddDropdownOptionsPopup(false);
  // };

  // const handleAddDropdownOptionsSave = () => {
  //   setFormFields((prevFields) => [...prevFields, { ...newField }]);
  //   setNewField((prevField) => ({
  //     label: '',
  //     type: 'text',
  //     options: [...prevField.options],
  //   }));
  //   handleAddDropdownOptionsPopupClose();
  // };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    setIsEditing(false);
  };
  const handleEditToggleForTitle = () => {
    setIsEditingTitle(!isEditingTitle);
  };

  const handleSaveForTitle = () => {
    setIsEditingTitle(false);
  };
  const handleSaveForButton = () => {
    setIsEditingButton(false);
  };
  // const handleEditToggleForThankYouMsg = () => {
  //   setIsEditingThankuouMsg(!isEditingButton);
  // };
  // const handleSaveForThankYouMsg = () => {
  //   setIsEditingThankuouMsg(false);
  // };
  const visibleContent =
    content.length > maxVisibleChars ? `${content.slice(0, maxVisibleChars)}...` : content;
  const visibleContentTitle =
    content.length > maxVisibleCharsForTitle
      ? `${contentTitle.slice(0, maxVisibleCharsForTitle)}...`
      : contentTitle;

  return (
    <>
      <div className="landing-page  align-items-center p-4 bgColor">
        <Grid className="d-flex background p-3">
          <Grid
            item
            size={{ xs: 4 }}
            className="d-flex flex-column justify-content-end align-items-center bg-white temp p-4"
          >
            <div className="align-items-center">
              <div className="">
                <div className="pb-5 p-4">
                  <div className="mb-2">
                    <Typography variant="body2" color="textSecondary">
                      Note: Your logo should have a maximum width of 300px and a maximum height of
                      150px.
                    </Typography>
                  </div>
                  <div
                    elevation={3}
                    className="p-3 d-flex align-items-center justify-content-center logoImg"
                  >
                    {landingFormData?.logoUrl ? (
                      <div>
                        <img
                          alt="Profile Preview"
                          src={`${CONTENT_URL}/${landingFormData?.logoUrl}`}
                          className="mt-2 logoImg"
                        />
                      </div>
                    ) : (
                      <div>
                        <img
                          alt="Profile Preview"
                          src={'https://content.adzylytics.com/static/images/adzylytics_logo.png'}
                          className="mt-2 logoImg"
                        />
                      </div>
                    )}
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id="profile-logo-upload"
                      type="file"
                      onChange={handleLogoChange}
                    />
                    <div className="mr-2 d-flex align-items-center">
                      <label htmlFor="profile-logo-upload">
                        <Tooltip title="Upload Logo" arrow>
                          <IconButton color="primary" aria-label="upload picture" component="span">
                            <EditIcon className="iconsColor" />
                          </IconButton>
                        </Tooltip>
                      </label>
                    </div>
                  </div>

                  <div className="justify-content-center d-flex  mb-2">
                    {isEditing ? (
                      <>
                        <textarea
                          value={content}
                          onChange={(e) => setContent(e.target.value)}
                          className="mb-3 textarea"
                          // style={{ width: '100%', minHeight: '50px', color: 'var(--primary)' }}
                        />
                        <IconButton onClick={handleSave} aria-label="save">
                          <SaveIcon className="iconsColor" />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography component="h6">
                          {visibleContent}
                          <Tooltip title="Edit Text" arrow>
                            <IconButton onClick={handleEditToggle} aria-label="edit">
                              <EditIcon className="iconsColor" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </>
                    )}
                  </div>

                  <div className="d-flex justify-content-center mb-3">
                    {isEditingTitle ? (
                      <>
                        <textarea
                          value={contentTitle}
                          onChange={(e) => setContentTitle(e.target.value)}
                          className="textarea"
                          // style={{ width: '100%', minHeight: '50px', color: 'var(--primary)' }}
                        />
                        <IconButton onClick={handleSaveForTitle} aria-label="save">
                          <SaveIcon className="iconsColor" />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <Typography variant="h5">
                          {visibleContentTitle}
                          <Tooltip title="Edit Text" arrow>
                            <IconButton onClick={handleEditToggleForTitle} aria-label="edit">
                              <EditIcon className="iconsColor" />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </>
                    )}
                  </div>

                  <Grid item size={{ xs: 12, md: 6 }}>
                    <Paper elevation={3} className="p-2" style={{ width: '350px' }}>
                      <form onSubmit={(e) => handleSubmit(e, false)}>
                        {formFields.map((field, index) => (
                          <div key={index} className="mb-2">
                            {field.type === 'text' && (
                              <TextField
                                label={field.label}
                                name={field.label}
                                fullWidth
                                variant="outlined"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip title="Edit Field Name" arrow>
                                        <IconButton
                                          onClick={() => handleFieldEditOpen(index)}
                                          edge="end"
                                          aria-label="edit"
                                        >
                                          <EditIcon className="iconsColor" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete Field" arrow>
                                        <IconButton
                                          onClick={() => handleDeleteFields(index)}
                                          edge="end"
                                        >
                                          <DeleteIcon className="iconsColor" />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}

                            {field.type === 'dropdown' && (
                              <Grid container alignItems="center">
                                <Grid item size={{ xs: 10 }}>
                                  <FormControl fullWidth variant="outlined">
                                    <InputLabel>{field.label}</InputLabel>
                                    <Select label={field.label} value="" onChange={() => {}}>
                                      {field.options.map((option, optionIndex) => (
                                        <MenuItem key={optionIndex} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                                <Grid item size={{ xs: 1 }}>
                                  <Tooltip title="Edit DropDown" arrow>
                                    <IconButton
                                      edge="end"
                                      aria-label="edit"
                                      onClick={() => handleFieldEditOpen(index)}
                                    >
                                      <EditIcon className="iconsColor" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                                <Grid item size={{ xs: 1 }}>
                                  <Tooltip title="Delete DropDown" arrow>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      className="ml-3"
                                      onClick={() => handleDeleteFields(index)}
                                    >
                                      <DeleteIcon className="iconsColor" />
                                    </IconButton>
                                  </Tooltip>
                                </Grid>
                              </Grid>
                            )}

                            {field.type === 'email' && (
                              <TextField
                                label={field.label}
                                name={field.label}
                                fullWidth
                                variant="outlined"
                                type="email"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip title="Edit Field Name" arrow>
                                        <IconButton
                                          onClick={() => handleFieldEditOpen(index)}
                                          edge="end"
                                          aria-label="edit"
                                        >
                                          <EditIcon className="iconsColor" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete Field" arrow>
                                        <IconButton
                                          onClick={() => handleDeleteFields(index)}
                                          edge="end"
                                        >
                                          <DeleteIcon className="iconsColor" />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}

                            {field.type === 'number' && (
                              <TextField
                                label={field.label}
                                name={field.label}
                                fullWidth
                                variant="outlined"
                                type="number"
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <Tooltip title="Edit Field Name" arrow>
                                        <IconButton
                                          onClick={() => handleFieldEditOpen(index)}
                                          edge="end"
                                          aria-label="edit"
                                        >
                                          <EditIcon className="iconsColor" />
                                        </IconButton>
                                      </Tooltip>
                                      <Tooltip title="Delete Field" arrow>
                                        <IconButton
                                          onClick={() => handleDeleteFields(index)}
                                          edge="end"
                                        >
                                          <DeleteIcon className="iconsColor" />
                                        </IconButton>
                                      </Tooltip>
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            )}
                            {index === formFields.length - 1 && (
                              <Tooltip title="Add Field" arrow>
                                <IconButton
                                  color="primary"
                                  aria-label="add-field"
                                  onClick={handleAddFieldPopupOpen}
                                  className="mr-5"
                                  style={{ marginLeft: '240px' }}
                                >
                                  <AddCircleOutlineIcon
                                    className="iconsColor"
                                    style={{ fontSize: '30px' }}
                                  />
                                </IconButton>
                              </Tooltip>
                            )}
                          </div>
                        ))}

                        <div className="d-flex justify-content-center mb-3">
                          {isEditingButton ? (
                            <>
                              <input
                                type="text"
                                value={submitButtonName}
                                onChange={(e) => setSubmitButtonName(e.target.value)}
                                style={{ width: '150px', marginRight: '10px' }}
                              />
                              <Tooltip title="Save Button Name" arrow>
                                <IconButton onClick={handleSaveForButton} aria-label="save">
                                  <SaveIcon className="iconsColor" />
                                </IconButton>
                              </Tooltip>
                            </>
                          ) : (
                            <>
                              <Button
                                disabled={leadFormData?.published}
                                type="submit"
                                variant="contained"
                                color="primary"
                                className="mt-4 ml-3 btn btn-primary"
                              >
                                {submitButtonName}
                              </Button>
                              <Tooltip title="Edit Button Name" arrow>
                                <IconButton
                                  edge="end"
                                  aria-label="edit"
                                  onClick={() => setIsEditingButton(true)}
                                >
                                  <EditIcon className="iconsColor" />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        </div>
                      </form>
                    </Paper>
                  </Grid>

                  <Dialog open={showAddFieldPopup} onClose={handleAddFieldPopupClose}>
                    <DialogTitle>Add New Field</DialogTitle>
                    <DialogContent>
                      <NewTextField
                        label="Field Label"
                        name="label"
                        value={newField.label}
                        onChange={handleInputChange}
                        fullWidth
                        variant="outlined"
                        className="mb-3"
                      />
                      <FormControl
                        variant="outlined"
                        className="mt-3"
                        style={{ minWidth: '120px' }}
                      >
                        <InputLabel>Type</InputLabel>
                        <Select
                          name="type"
                          value={newField.type}
                          onChange={handleInputChange}
                          label="Type"
                        >
                          <MenuItem value="text">Text</MenuItem>
                          <MenuItem value="dropdown">Dropdown</MenuItem>
                          <MenuItem value="email">Email</MenuItem>
                          <MenuItem value="number">Number</MenuItem>
                        </Select>
                      </FormControl>
                      {newField.type === 'dropdown' && (
                        <div className="mt-2">
                          <NewTextField
                            label="Option"
                            name="option"
                            value={newField.option}
                            onChange={(e) =>
                              setNewField({
                                ...newField,
                                option: e.target.value,
                              })
                            }
                            variant="outlined"
                            className="mr-3 mt-3"
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddOption}
                            className="mt-3 btn btn-primary"
                          >
                            Add Option
                          </Button>
                          <ul>
                            {newField.options.map((option, index) => (
                              <li key={index}>{option}</li>
                            ))}
                          </ul>
                        </div>
                      )}
                    </DialogContent>
                    <DialogActions>
                      <div style={{ flexGrow: 1 }}>
                        <Button
                          onClick={handleAddFieldPopupClose}
                          color="primary"
                          className=" mt-4 btn btn-secondary"
                        >
                          Close
                        </Button>
                      </div>

                      <Button
                        onClick={handleAddFieldSave}
                        variant="contained"
                        color="primary"
                        className="mt-4 btn btn-primary"
                      >
                        Save
                      </Button>
                    </DialogActions>
                  </Dialog>
                  {isDropdownField ? (
                    <Dialog
                      open={editDialogOpen}
                      onClose={handleEditDialogClose}
                      aria-labelledby="edit1-options-dialog-title"
                    >
                      <DialogTitle id="edit1-options-dialog-title">Edit Options</DialogTitle>
                      {isDropdownField && SelectedFieldIndex !== null && (
                        <DialogContent>
                          <List>
                            {(formFields[SelectedFieldIndex]?.options || []).map(
                              (option, optionIndex) => (
                                <ListItem key={optionIndex}>
                                  <ListItemText primary={option} />
                                  <ListItemSecondaryAction>
                                    <IconButton
                                      edge="end"
                                      aria-label="edit"
                                      onClick={() =>
                                        handleEditDialogOpen(SelectedFieldIndex, optionIndex)
                                      }
                                    >
                                      <EditIcon className="iconsColor" />
                                    </IconButton>
                                    <IconButton
                                      edge="end"
                                      aria-label="delete"
                                      onClick={() =>
                                        handleDeleteOption(SelectedFieldIndex, optionIndex)
                                      }
                                    >
                                      <DeleteIcon className="iconsColor" />
                                    </IconButton>
                                  </ListItemSecondaryAction>
                                </ListItem>
                              ),
                            )}
                          </List>
                        </DialogContent>
                      )}

                      <NewTextField
                        label="New Option"
                        value={editedOption}
                        onChange={(e) => setEditedOption(e.target.value)}
                        variant="outlined"
                        style={{ margin: '16px' }}
                      />

                      <DialogActions>
                        <Button onClick={handleEditDialogClose} className=" mt-4 btn btn-secondary">
                          Close
                        </Button>
                        <Button
                          onClick={handleEditOption}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="mt-4 btn btn-primary"
                        >
                          Save Changes
                        </Button>
                        <Button
                          onClick={handleAddNewOption}
                          type="submit"
                          variant="contained"
                          color="primary"
                          className="mt-4 btn btn-primary"
                        >
                          Add New Option
                        </Button>
                      </DialogActions>
                    </Dialog>
                  ) : (
                    <Dialog
                      open={editDialogOpen}
                      onClose={handleEditDialogClose}
                      aria-labelledby="edit-options-dialog-title"
                    >
                      <DialogTitle id="edit-options-dialog-title">Edit Fields</DialogTitle>
                      <DialogContent>
                        {editedFieldIndex !== null && (
                          <>
                            <NewTextField
                              label="New Label"
                              fullWidth
                              name="editedLabel"
                              value={editedLabel}
                              onChange={handleSpecialFieldEditChange}
                              variant="outlined"
                              className="mb-3"
                            />
                            <FormControl
                              variant="outlined"
                              className="mt-3"
                              style={{ minWidth: '120px' }}
                            >
                              <InputLabel>New Type</InputLabel>
                              <Select
                                name="editedType"
                                value={editedType}
                                onChange={handleSpecialFieldEditChange}
                                label="New Type"
                              >
                                <MenuItem value="text">Text</MenuItem>
                                {/* <MenuItem value="dropdown">Dropdown</MenuItem> */}
                                <MenuItem value="email">Email</MenuItem>
                                <MenuItem value="number">Number</MenuItem>
                              </Select>
                            </FormControl>
                          </>
                        )}
                      </DialogContent>
                      <DialogActions>
                        <div style={{ flexGrow: 1 }}>
                          <Button
                            onClick={handleEditDialogClose}
                            className="mt-4 btn btn-secondary"
                          >
                            Close
                          </Button>
                        </div>
                        {editedFieldIndex !== null && (
                          <Button
                            onClick={handleSpecialFieldEditSave}
                            variant="contained"
                            color="primary"
                            className="mt-4 btn btn-primary"
                          >
                            Save Changes
                          </Button>
                        )}
                      </DialogActions>
                    </Dialog>
                  )}
                </div>
              </div>

              <ConformationBox
                isOpen={!!conformation}
                handleClose={() => setConformation()}
                handleSubmit={(e) => handleSubmit(e, true)}
                title="confirm ?"
                subtitle="Are you sure you want to Publish this lead form? Once publish it cannot be rollback."
                btnCloseLabel="Close"
                btnSubmitLabel="confirm"
              />
            </div>
          </Grid>
        </Grid>
        <div
          className="flex"
          style={{ position: 'absolute', bottom: '45px', right: 0, marginRight: '40px' }}
        >
          <Button
            className="btn btn-primary"
            disabled={leadFormData?.published}
            onClick={handleSubmit}
          >
            Save and next
          </Button>
          {isLoadingCreateleadForm && <OverlapLoader />}

          {/* {leadFormData?.id && (
            <Button
              variant="contained"
              className="btn btn-primary ml-5"
              disabled={leadFormData?.published}
              onClick={(e) => {
                e.preventDefault();
                setConformation(true);
              }}
            >
              Update and Publish
            </Button>
          )} */}
        </div>
      </div>
    </>
  );
};
